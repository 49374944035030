import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useSidebarState from "../../hooks/useSidebarState";
import ProjectDropdown from "../projects/ProjectDropdown";
import useProjects from "../../hooks/useProjects";
import useSidebarLinks from "../../hooks/useSidebarLinks";
import SidebarContent from "./SidebarContent";
import ApiClient from "../../utils/ApiClient";
import ProjectImage from "./ProjectImage";

const Sidebar = ({ pageName, project, currentUser }) => {
  const [currentProject, setCurrentProject] = useState(project || {});
  const { isExpanded, toggle } = useSidebarState(false);
  const { projectOptions } = useProjects(project?.id) || {};
  const { links, bottomLinks } = useSidebarLinks(
    pageName,
    currentProject,
    currentUser
  );

  /* leaving hardcoded projectSwitch boolean, this was previously passed down as a prop
   * to determine whether to render the project switcher component. for now, just defaulting
   * to render everywhere, if we need to hide it can implement logic for that in the future.
   */
  const projectSwitch = true;

  const expandSidebar = () => {
    toggle();
  };

  const handleMouseEnter = () => {
    if (!isExpanded) {
      expandSidebar();
    }
  };

  const handleMouseLeave = () => {
    if (isExpanded) {
      expandSidebar();
    }
  };

  const handleProjectChange = (projectData) => {
    const api = new ApiClient();
    api
      .get(`/v1/projects/${projectData.value}/dashboard.json`)
      .then((res) => {
        setCurrentProject(res.data.project);
      })
      .catch((err) => {
        console.error("Project Change Frontend error: ", err);
      });
  };

  return (
    <Container
      id="Sidebar"
      role="navigation"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $isExpanded={isExpanded}
    >
      <LogoContainer $projectSwitch={projectSwitch}>
        <LogoLink href="/" aria-label="home page">
          <LogoImage
            alt="Test Platform app logo"
            aria-hidden="true"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TPLogo_2021_Color.png"
          />
          <LogoText $isExpanded={isExpanded}>Test Platform</LogoText>
        </LogoLink>
        {projectSwitch && (
          <ContentContainer>
            {!isExpanded && (
              <ProjectImageContainer>
                <ProjectImage project={currentProject} />
              </ProjectImageContainer>
            )}
            <ProjectDropdown
              handleProjectChange={handleProjectChange}
              project={currentProject}
              projectOptions={projectOptions}
              projectImage={<ProjectImage project={currentProject} />}
              expanded={isExpanded}
            />
          </ContentContainer>
        )}
      </LogoContainer>
      <SidebarContent
        links={links}
        bottomLinks={bottomLinks}
        isExpanded={isExpanded}
        pageName={pageName}
        project={project}
        currentUser={currentUser}
      />
    </Container>
  );
};

const { bool, string, arrayOf, shape, number } = PropTypes;

Sidebar.propTypes = {
  currentUser: shape({
    additional_permissions: arrayOf(string),
    avatar_image: string,
    email: string,
    employee_started_at: string,
    fact: string,
    id: number,
    name: string,
    role: string,
    slack_id: string,
  }),
  pageName: string,
  project: shape({
    a11y_active: bool,
    active: bool,
    color: string,
    created_at: string,
    has_bugs: bool,
    has_test_cases: bool,
    icon_url: string,
    id: number,
    identifier: string,
    media: bool,
    name: string,
  }),
};

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: ${(props) => (props.$isExpanded ? "250px" : "100px")};
  left: 0;
  right: 0;
  bottom: 0;
  transition: max-width 0.5s ease-in-out 0s;
  will-change: max-width;
  background: white;
  overflow: hidden;
  z-index: 1000;
  box-shadow: lightgrey 1px 0px 0px 0px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: ${(props) => (props.$projectSwitch ? "auto" : "100px")};
  width: 255px;
  padding-top: 40px;
`;

const LogoLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.projectSwitch ? "20px" : "0")};
`;

const LogoImage = styled.img`
  padding: 0;
  width: 40px;
`;

const LogoText = styled.p`
  margin-left: 10px;
  opacity: ${(props) => (props.$isExpanded ? "1" : "0")};
  transition: 0.4s opacity;
  font-size: 21px;
  color: #242424;
  font-family: manrope;
  font-weight: 800;
`;

const ProjectImageContainer = styled.div`
  left: 42px;
  position: absolute;
  top: 98px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export default Sidebar;
