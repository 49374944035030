import React from "react";
import moment from "moment";
import { timeRangeIcons } from "../common/icons";
import RoundDropdown from "../common/round_dropdown";

const today = moment(new Date()).format("YYYY-MM-DD");
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const SCOPE_KEYS = [
  "today",
  "yesterday",
  "last_thirty",
  "current_week",
  "current_month",
  "current_year",
  "all_time",
  "custom_range",
];
const [
  TODAY,
  YESTERDAY,
  LAST_THIRTY,
  CURRENT_WEEK,
  CURRENT_MONTH,
  CURRENT_YEAR,
  ALL_TIME,
  CUSTOM_RANGE,
] = SCOPE_KEYS;

class DashboardTimeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      custom: false,
      customSubmited: false,
      open: false,
      startDate: "",
      endDate: today,
    };

    this.checkboxMenuContainerTime = React.createRef();
    this.handleOutsideClick = (e) => {
      if (
        this.checkboxMenuContainerTime &&
        this.checkboxMenuContainerTime.current &&
        !this.checkboxMenuContainerTime.current.contains(e.target) &&
        this.state.open
      ) {
        this.setOpen();
      }
    };
    this.selectedIcons = {
      [TODAY]: (
        <timeRangeIcons.TodaySelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [YESTERDAY]: (
        <timeRangeIcons.YesterdaySelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [CURRENT_WEEK]: (
        <timeRangeIcons.WeekSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [LAST_THIRTY]: (
        <timeRangeIcons.Last30Selected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [CURRENT_MONTH]: (
        <timeRangeIcons.MonthSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [ALL_TIME]: (
        <timeRangeIcons.AllTimeSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      [CUSTOM_RANGE]: (
        <timeRangeIcons.CustomDateSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
    };
  }

  setOpen = () => {
    if (!this.state.open === true) {
      window.addEventListener("click", this.handleOutsideClick);
      this.setState(
        {
          open: true,
          selectOnOpen: this.props.scope,
        },
        () => {
          const elem = document.getElementById("time-checkbox-menu");
          setTimeout(() => {
            elem.style.maxHeight = this.props.maxHeight
              ? this.props.maxHeight
              : "270px";
            const optionElems = document.querySelectorAll(
              "#time-checkbox-menu > div > div"
            );
            optionElems.forEach((elm, index) => {
              elm.style.transitionDelay = `${
                (0.3 / optionElems.length) * index
              }s`;
              elm.style.transform = "translateX(0px)";
              elm.style.opacity = "1";
            });
          }, 50);
          setTimeout(() => {
            elem.style.transitionDelay = "0.2s";
            elem.style.overflow = "visible";
          }, 450);
        }
      );
    } else {
      window.removeEventListener("click", this.handleOutsideClick);
      const elem = document.getElementById("time-checkbox-menu");
      elem.style.overflow = "hidden";
      elem.style.maxHeight = "0px";
      const optionElems = document.querySelectorAll(
        "#time-checkbox-menu > div > div"
      );
      optionElems.forEach((elm, index) => {
        elm.style.transitionDelay = `${
          (0.4 / optionElems.length) * (optionElems.length - (index + 1))
        }s`;
        elm.style.transform = "translateX(-14px)";
        elm.style.opacity = "0";
      });
      setTimeout(() => {
        this.setState({
          open: false,
          custom: false,
          selected:
            !this.state.customSubmited && this.props.scope === "custom"
              ? this.state.selectOnOpen
              : this.props.scope,
        });
      }, 600);
    }
  };

  handleSelect = (e) => {
    const event = e;
    if (event.target.value === CUSTOM_RANGE) {
      this.setState({ custom: true });
    } else {
      this.props.handler(e.target.value);
      this.setState({ custom: false, customSubmited: false }, () => {
        if (this.props.closeOnSelect) {
          this.setOpen();
        }
      });
    }
  };

  handleCustom = () => {
    this.setState((prevState) => ({ custom: !prevState.custom }));
  };

  submitData = (e) => {
    e.preventDefault();
    const { customHandler } = this.props;
    const { startDate: start, endDate: end } = this.state;

    const invalidStartToast = () => {
      // eslint-disable-next-line
      M.toast({
        html: "Invalid Start Date",
        displayLength: 5000,
        classes: "red",
      });
    };

    const invalidEndToast = () => {
      // eslint-disable-next-line
      M.toast({
        html: "Invalid End Date",
        displayLength: 5000,
        classes: "red",
      });
    };

    if (!this.validateDate(start)) return invalidStartToast();
    if (!this.validateDate(end)) return invalidEndToast();

    customHandler(start, end);
    this.setState({ custom: false, customSubmited: true, open: false });
  };

  validateDate = (input) => {
    return moment(input, "YYYY-MM-DD").isValid();
  };

  makeOptions = () => {
    const { scope } = this.props;
    const options = [
      {
        label: "Today",
        value: TODAY,
        handler: this.handleSelect,
        checked: scope === TODAY,
        icon:
          scope === TODAY ? (
            <timeRangeIcons.TodayFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.Today
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "Yesterday",
        value: YESTERDAY,
        handler: this.handleSelect,
        checked: scope === YESTERDAY,
        icon:
          scope === YESTERDAY ? (
            <timeRangeIcons.YesterdayFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.Yesterday
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "This Week",
        value: CURRENT_WEEK,
        handler: this.handleSelect,
        checked: scope === CURRENT_WEEK,
        icon:
          scope === CURRENT_WEEK ? (
            <timeRangeIcons.WeekFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.Week
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "Last 30 Days",
        value: LAST_THIRTY,
        handler: this.handleSelect,
        checked: scope === LAST_THIRTY,
        icon:
          scope === LAST_THIRTY ? (
            <timeRangeIcons.Last30Filled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.Last30
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "This Month",
        value: CURRENT_MONTH,
        handler: this.handleSelect,
        checked: scope === CURRENT_MONTH,
        icon:
          scope === CURRENT_MONTH ? (
            <timeRangeIcons.MonthFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.Month
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "All",
        value: ALL_TIME,
        handler: this.handleSelect,
        checked: scope === ALL_TIME,
        icon:
          scope === ALL_TIME ? (
            <timeRangeIcons.AllTimeFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.AllTime
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
      {
        label: "Custom",
        value: CUSTOM_RANGE,
        handler: this.handleCustom,
        checked: scope === CUSTOM_RANGE,
        icon:
          scope === CUSTOM_RANGE ? (
            <timeRangeIcons.CustomDateFilled
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ) : (
            <timeRangeIcons.CustomDate
              style={{ width: "38px", height: "38px", paddingRight: "6px" }}
              className="icons"
            />
          ),
      },
    ];
    return options.map((option, index) => {
      return (
        <div
          key={index}
          className={option.classList}
          style={{
            height: "35px",
            marginTop: index === 0 ? "10px" : "0px",
            marginBottom: index === options.length - 1 ? "15px" : "0px",
            opacity: "0",
            transform: "translateX(-14px)",
            transition: " opacity 0.3s, transform 0.3s",
          }}
        >
          <label style={{ cursor: "pointer" }}>
            <input
              onChange={option.handler}
              className="filled-in dash-time-option"
              checked={option.checked}
              value={option.value}
              type="checkbox"
            />
            {option.icon}
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "11pt",
                color: option.checked ? "#519acc" : "black",
                paddingLeft: "0px",
                verticalAlign: "13px",
              }}
            >
              {option.label}
            </span>
          </label>
        </div>
      );
    });
  };

  changeStart = (e) => {
    this.setState({ startDate: e.target.value });
  };

  changeEnd = (e) => {
    this.setState({ startDate: e.target.value });
  };

  render() {
    const { scope } = this.props;
    const OPTION_LABELS = {
      [TODAY]: "Today",
      [YESTERDAY]: "Yesterday",
      [CURRENT_WEEK]: "This Week",
      [CURRENT_MONTH]: "This Month",
      [LAST_THIRTY]: "Last 30 days",
      [ALL_TIME]: "All",
      [CUSTOM_RANGE]: "Custom",
    };
    const text = OPTION_LABELS[scope];

    return (
      <div
        ref={this.checkboxMenuContainerTime}
        className="checkbox-menu-container"
        style={{
          position: "absolute",
          width: "fit-content",
          left: "200px",
          top: "9px",
          ...this.props.containerStyle,
        }}
      >
        <div
          className={`${
            this.state.open
              ? "checkbox-menu-selector-open"
              : "checkbox-menu-selector-closed"
          }`}
          onClick={this.setOpen}
          style={{
            height: "44px",
            padding: "0 2px 0 0",
            transition: "border-color 0.2s",
            color: this.state.active ? "white" : "initial",
            width: "180px",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "44px",
              whiteSpace: "nowrap",
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {this.selectedIcons[this.props.scope]}
            {/* <img style={{width:"38px", height:"38px", verticalAlign:"bottom"}} src={this.selectedIcons[this.props.scope]}/> */}
            <span
              style={{
                display: "inline-block",
                width: "85px",
                marginLeft: "-4px",
                textAlign: "center",
              }}
            >
              {text}
            </span>
            <RoundDropdown />
          </span>
        </div>
        {this.state.open && (
          <div
            className="checkbox-menu"
            id="time-checkbox-menu"
            style={{
              position: "absolute",
              overflow: "hidden",
              transition: "max-height 0.4s",
              zIndex: "13",
              backgroundColor: "#ffffffdb",
              right: "0px",
              top: "calc(100% + 8px)",
              padding: "0px 20px 0px 20px",
              maxWidth: "400px",
              maxHeight: "2px",
              width: "180px",
            }}
          >
            <div id="dashTimeSwitcher">
              {this.makeOptions()}
              {this.state.custom == true && (
                <div
                  className="checkbox-menu"
                  style={{
                    fontSize: "11pt",
                    left: "0px",
                    backgroundColor: "white",
                    position: "absolute",
                    borderTop: "1px solid #519acc",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: "1",
                  }}
                >
                  <form
                    style={{ display: "flex", flexDirection: "column" }}
                    onSubmit={this.submitData}
                  >
                    <div
                      style={{
                        height: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          height: "26px",
                          width: "153px",
                          background: "#ffffffb7",
                        }}
                        className="browser-default"
                        type="text"
                        id="date-start-text"
                        name={`address ${Math.random()}`}
                        placeholder="Start: YYYY-MM-DD"
                        onChange={this.changeStart}
                        value={this.state.startDate}
                        min="2019-01-01"
                        max={today}
                        autoFocus
                      />
                      {!isSafari && (
                        <i
                          style={{ cursor: "pointer", fontSize: "26px" }}
                          className="material-icons"
                        >
                          arrow_drop_down
                        </i>
                      )}
                      <input
                        style={{ background: "#ffffffb7" }}
                        className="browser-default"
                        type="date"
                        id="date-start-date"
                        name="date-start"
                        placeholder="yyyy-mm-dd"
                        onChange={this.changeStart}
                        min="2019-01-01"
                        max={today}
                      />
                    </div>
                    <div
                      style={{
                        height: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          height: "26px",
                          width: "153px",
                          background: "#ffffffb7",
                        }}
                        className="browser-default"
                        type="text"
                        id="date-end-text"
                        name={`address ${Math.random()}`}
                        placeholder="End: YYYY-MM-DD"
                        onChange={this.changeEnd}
                        value={this.state.endDate}
                        min="2019-01-01"
                        max={today}
                      />
                      {!isSafari && (
                        <i
                          style={{ cursor: "pointer", fontSize: "26px" }}
                          className="material-icons"
                        >
                          arrow_drop_down
                        </i>
                      )}
                      <input
                        style={{ paddingLeft: "10px", background: "#ffffffb7" }}
                        required
                        className="browser-default"
                        type="date"
                        id="date-end-date"
                        name="date-end"
                        defaultValue={moment(today).format("YYYY-MM-DD")}
                        onChange={this.changeEnd}
                        placeholder="yyyy-mm-dd"
                        min="2019-01-01"
                        max={today}
                      />
                    </div>
                    <button
                      disabled={this.state.startDate == ""}
                      type="submit"
                      style={{
                        cursor: `${
                          this.state.startDate == "" ? "intial" : "pointer"
                        }`,
                        paddingLeft: "10px",
                        background: "#ffffffb7",
                      }}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardTimeSelector;
