import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Activity from "./activity.jsx";

class ActivityFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeActivity: 0,
      loading: false,
      activities: {},
      expanded: {
        today: true,
        yesterday: true,
        week: true,
        month: true,
        last_month: true,
      },
    };
    this.activeUserHandler = this.activeUserHandler.bind(this);
    this.closeUser = this.closeUser.bind(this);
    this.expandHandler = this.expandHandler.bind(this);
  }

  activeUserHandler(id) {
    if (id != this.state.activeActivity) {
      this.setState({ activeActivity: id });
    } else {
      this.setState({ activeActivity: 0 });
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId != prevProps.projectId) {
      this.getData();
    }
  }

  displayLoader() {
    return (
      <div style={{ height: "100px" }}>
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  getData() {
    this.setState({ loading: true });
    const url = `/projects/${this.props.projectId}/activities.json`;
    axios
      .get(url)
      .then((res) => {
        this.setState({
          activities: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  closeUser() {
    this.setState({ activeActivity: 0 });
  }

  expandHandler(e) {
    const { key } = e.target.dataset;
    const { expanded } = this.state;
    const newExpanded = { ...expanded }; // create a copy of the object to avoid mutating the state directly

    if (newExpanded[key]) {
      delete newExpanded[key];
    } else {
      newExpanded[key] = true;
    }

    this.setState({ expanded: newExpanded });
  }

  render() {
    const { activities, loading, expanded } = this.state;

    const hasActivities = Object.values(activities).some((x) => x.length >= 1);
    const feedContainerStyles = {
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: loading ? "center" : "flex-start",
      flexDirection: "column",
    };
    const activityKeys = Object.keys(activities);
    const feedContent = [];

    if (hasActivities) {
      activityKeys.forEach((key) => {
        if (activities[key] && activities[key].length >= 1) {
          feedContent.push(
            <div
              key={key}
              className="activity-sub"
              data-key={key}
              onClick={this.expandHandler}
              style={{
                maxHeight: expanded[key]
                  ? `${activities[key].length * 90 + 30}px`
                  : "0px",
              }}
            >
              <div
                className="flex-container flex-al-center"
                data-key={key}
                style={{
                  backgroundColor: "#ffffffe3",
                  backdropFilter: "blur(1px)",
                  position: "sticky",
                  top: "0px",
                  zIndex: 1,
                }}
              >
                <p className={expanded[key] ? "collapsed" : ""}>
                  {key.includes("day")
                    ? key
                    : key.includes("last")
                    ? "Last Month"
                    : `This ${key}`}
                </p>
                <span />
              </div>

              {activities[key].map((x) => (
                <Activity
                  key={x.id}
                  activity={x}
                  big={this.props.big}
                  length={activities[key].length}
                  activeCardHandler={this.activeUserHandler}
                  closeUser={this.closeUser}
                />
              ))}
            </div>
          );
        }
      });

      if (feedContent.length === 0) {
        feedContent.push(
          <div key="no-activity">
            <p>No Activity</p>
          </div>
        );
      }
    } else {
      feedContent.push(
        <div key="no-activity">
          {loading ? (
            this.displayLoader()
          ) : (
            <p style={{ textAlign: "center" }}>No Activity</p>
          )}
        </div>
      );
    }

    return (
      <div
        id="feedContainer"
        style={{
          ...feedContainerStyles,
          minHeight: `${activityKeys.length * 35}px`,
        }}
      >
        {loading ? this.displayLoader() : feedContent}
      </div>
    );
  }
}

export default ActivityFeed;

ActivityFeed.propTypes = {
  projectId: PropTypes.number,
  big: PropTypes.bool,
};
