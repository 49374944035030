import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import moment from "moment";
import xss from "xss";
import ErrorBoundary from "../../common/error_boundary.jsx";

const CancelToken = axios.CancelToken;

class TestPassHoverDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: [],
      bugs: [],
      wcags: [],
      progress: "",
      type: "comments",
      scrollPosition: "start",
    };
    this.cache = {};
    this.bodyElement = document.querySelector("body");
    this.cancelRequest = null;
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  getProgressLabel = (progress) => {
    this.setState({
      type: "progress",
      loading: false,
      comments: [],
      bugs: [],
      wcags: [],
      progress,
    });
  };

  getTestStepA11y = (id) => {
    this.setState({
      type: "a11y",
      loading: true,
      comments: [],
      bugs: [],
      wcags: [],
      progress: "",
    });
    axios
      .get(`/get_test_step_a11ies/${id}`, {
        cancelToken: new CancelToken((c) => {
          this.cancelRequest = c;
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // setTimeout(()=>{
        this.cancelRequest = null;
        var wcags = res.data;
        wcags.sort((a, b) => {
          if (a.violation.toLowerCase() < b.violation.toLowerCase()) {
            return -1;
          } else if (a.violation.toLowerCase() > b.violation.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
        this.setState(
          {
            wcags: wcags,
            loading: false,
          },
          () => {
            var elem = document.getElementById("test-pass-hover-dialog-inner");
            elem.style.opacity = "1";
            document.getElementById("test-pass-hover-dialog").style.height =
              elem.scrollHeight > elem.clientHeight
                ? "160px"
                : `${elem.clientHeight}px`;
          }
        );
        var innerElem = document.getElementById("test-pass-hover-dialog-inner");
        innerElem.style.transition = "opacity 0.2s";
        innerElem.style.transitionDelay = "0.3s";
        // },4000)
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          console.error(err);
        }
      });
  };

  getTestStepBugs = (id, device) => {
    this.setState({
      type: "bugs",
      loading: true,
      comments: [],
      bugs: [],
      wcags: [],
      progress: "",
    });
    axios
      .get(`/get_test_step_bugs/${id}`, {
        cancelToken: new CancelToken((c) => {
          this.cancelRequest = c;
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: device ? { device: device } : {},
      })
      .then((res) => {
        // setTimeout(()=>{
        this.cancelRequest = null;
        var bugs = res.data;
        this.setState(
          {
            bugs: bugs,
            loading: false,
          },
          () => {
            var elem = document.getElementById("test-pass-hover-dialog-inner");
            elem.style.opacity = "1";
            document.getElementById("test-pass-hover-dialog").style.height =
              elem.scrollHeight > elem.clientHeight
                ? "160px"
                : `${elem.clientHeight}px`;
          }
        );
        var innerElem = document.getElementById("test-pass-hover-dialog-inner");
        innerElem.style.transition = "opacity 0.2s";
        innerElem.style.transitionDelay = "0.3s";
        // },4000)
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          console.error(err);
        }
      });
  };

  getTestStepComments = (id, device, count) => {
    var self = this;
    this.setState({
      type: "comments",
      loading: true,
      comments: [],
      bugs: [],
      wcags: [],
      progress: "",
    });
    if (
      this.cache[`${id}-${device}`] &&
      Object.values(this.cache[`${id}-${device}`]).length === count
    ) {
      var comments = Object.values(this.cache[`${id}-${device}`]);
      this.setState(
        {
          comments: comments,
          loading: false,
        },
        () => {
          var elem = document.getElementById("test-pass-hover-dialog-inner");
          elem.style.opacity = "1";
          document.getElementById("test-pass-hover-dialog").style.height =
            elem.scrollHeight > elem.clientHeight
              ? "160px"
              : `${elem.clientHeight}px`;
        }
      );
    } else {
      axios
        .get(`/test_steps/${id}/comments`, {
          cancelToken: new CancelToken((c) => {
            this.cancelRequest = c;
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            device: device,
          },
        })
        .then((res) => {
          // setTimeout(()=>{
          this.cancelRequest = null;
          var comments = res.data;
          for (var x = 0; x < comments.length; x++) {
            if (comments[x].comment) {
              var comment = comments[x].comment.replace("\n", " \n ");
              if (
                comment.includes("http:") ||
                comment.includes("https:") ||
                comments[x].comment.includes("www.")
              ) {
                var commentArray = comment.split(" ");
                for (var y = 0; y < commentArray.length; y++) {
                  if (
                    commentArray[y].includes("http:") ||
                    commentArray[y].includes("https:")
                  ) {
                    commentArray[
                      y
                    ] = `<a target="_blank" href="${commentArray[y]}">${commentArray[y]}</a>`;
                  } else if (commentArray[y].includes("www.")) {
                    commentArray[
                      y
                    ] = `<a target="_blank" href="http://${commentArray[y]}">${commentArray[y]}</a>`;
                  }
                }
                comments[x].comment = commentArray.join(" ");
              }
              if (self.cache[`${id}-${device}`] == undefined) {
                self.cache[`${id}-${device}`] = {};
              }
              self.cache[`${id}-${device}`][comments[x].id] = comments[x];
              self.cache[`${id}-${device}`][comments[x].id]["comment"] =
                comment;
              // self.cache[parseInt(id)][comments[x].id] = comments[x];
              // self.cache[parseInt(id)][comments[x].id]["comment"] = comment
            }
          }
          this.setState(
            {
              comments: comments,
              loading: false,
            },
            () => {
              var elem = document.getElementById(
                "test-pass-hover-dialog-inner"
              );
              elem.style.opacity = "1";
              document.getElementById("test-pass-hover-dialog").style.height =
                elem.scrollHeight > elem.clientHeight
                  ? "160px"
                  : `${elem.clientHeight}px`;
            }
          );
          var innerElem = document.getElementById(
            "test-pass-hover-dialog-inner"
          );
          innerElem.style.transition = "opacity 0.2s";
          innerElem.style.transitionDelay = "0.3s";
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
          } else {
            console.error(err);
          }
        });
    }
  };

  setScrollPosition = (position) => {
    if (this.state.scrollPosition !== position) {
      this.setState({
        scrollPosition: position,
      });
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div
        id="test-pass-hover-dialog-container"
        style={{
          position: "fixed",
          display: "flex",
          overflow: "hidden",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          opacity: "0",
          zIndex: "1001",
          pointerEvents: "none",
          width: this.state.type === "progress" ? "auto" : "400px",
          height: this.state.type === "progress" ? "35px" : "160px",
          transition: "opacity 0.3s, transform 0.4s",
          transitionDelay: "0.2s, 0s",
          padding: this.state.type === "progress" ? "5px" : "0px",
          backgroundColor: "transparent",
        }}
      >
        <ErrorBoundary>
          <div
            id="test-pass-hover-dialog"
            style={{
              position: "relative",
              backgroundColor: "white",
              border: "solid 1px #519acc",
              borderRadius: "5px",
              display: "block",
              overflow: "hidden",
              zIndex: "1001",
              pointerEvents: "none",
              width:
                this.state.type === "progress"
                  ? "auto"
                  : !this.state.loading
                  ? "400px"
                  : "400px",
              height: "104px",
              transition: "height 0.4s",
              transitionDelay: "0.1s",
              boxShadow:
                this.state.type === "progress"
                  ? "1px 1px 3px #0000005e"
                  : "none",
            }}
          >
            {this.state.loading &&
              (this.state.type === "comments" ? (
                <div
                  id="comment-hover-loader"
                  style={{ position: "relative", top: "0px", left: "0px" }}
                >
                  <span className="comment-title-loader"></span>
                  <span
                    style={{
                      display: "inline-block",
                      borderLeft: "solid 1px lightgray",
                      height: "54px",
                      marginRight: "5px",
                      marginLeft: "20px",
                    }}
                  ></span>
                  <div style={{ display: "inline-block" }}>
                    <span className="comment-text-loader"></span>
                    <span className="comment-text-loader"></span>
                  </div>
                </div>
              ) : (
                <div
                  id="comment-hover-loader"
                  style={{ position: "relative", top: "0px", left: "0px" }}
                >
                  <span className="bug-title-loader"></span>
                  <span className="bug-title-loader"></span>
                </div>
              ))}
            <div
              id="test-pass-hover-dialog-inner"
              className="new-scrollbar"
              style={{
                width: this.state.type === "progress" ? "auto" : "398px",
                maxHeight: "160px",
                overflow: "auto",
                opacity: "0",
                padding: this.state.type === "progress" ? "4px" : "10px",
              }}
            >
              {this.state.type === "comments" ? (
                this.state.comments.map((comment) => {
                  return (
                    <div id="comment-hover-text" key={comment.id}>
                      <span className="test-step-comment-title">
                        {comment.name} -{" "}
                        {moment(comment.created_at).format("lll")}
                      </span>
                      <span
                        className="test-step-comment-text"
                        dangerouslySetInnerHTML={{
                          __html: xss(comment.comment),
                        }}
                      ></span>
                    </div>
                  );
                })
              ) : this.state.type === "bugs" ? (
                this.state.bugs.map((bug, index) => {
                  var status = bug.ticket_status;
                  return (
                    <div id="comment-hover-text" key={bug.ticket_id}>
                      <span
                        className="test-step-comment-title"
                        style={{
                          marginBottom:
                            index !== this.state.bugs.length - 1
                              ? "8px"
                              : "initial",
                          lineHeight: "20px",
                        }}
                      >
                        <a href={bug.ticket_url} target="_blank">
                          {bug.ticket_identifier}
                        </a>
                        {` - ${bug.ticket_title} - `}
                        <span
                          style={{
                            color: `${
                              status === 1
                                ? "green"
                                : status === 2 || status === 5
                                ? "black"
                                : status === 3
                                ? "red"
                                : "#cab200"
                            }`,
                          }}
                        >
                          {status === 1
                            ? "Open"
                            : status === 2
                            ? "Closed"
                            : status === 3
                            ? "Fixed"
                            : status === 5
                            ? "Won't Fix"
                            : "In Progress"}
                        </span>
                      </span>
                    </div>
                  );
                })
              ) : this.state.type === "a11y" ? (
                this.state.wcags.map((wcag, index) => {
                  return (
                    <div id="comment-hover-text" key={wcag.id}>
                      <span
                        className="test-step-comment-title"
                        style={{
                          marginBottom:
                            index !== this.state.wcags.length - 1
                              ? "8px"
                              : "initial",
                          lineHeight: "20px",
                        }}
                      >
                        {wcag.violation}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div id="comment-hover-text">
                  <span
                    className="test-step-comment-title"
                    style={{
                      lineHeight: "20px",
                      paddingLeft: "0px",
                      fontWeight: "400",
                      fontStyle: "normal",
                    }}
                  >
                    {this.state.progress}
                  </span>
                </div>
              )}
            </div>
          </div>
        </ErrorBoundary>
      </div>,
      this.bodyElement
    );
  }
}

export default TestPassHoverDialog;
