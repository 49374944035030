import React from "react";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import axios from "axios";
import ApiClient from "@utils/ApiClient";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import _ from "lodash";
import TestSuiteForm from "./test_suite_form";
import ReactModalV2 from "../../modals/react_modal_v2";
import TestSuiteSideMenu from "./test_suite_side_menu";
import NewTestPass from "../../modals/new_test_pass";
import NewBadge from "../../modals/new_badge";

const modals = {
  "new-test-pass": NewTestPass,
  "new-badge": NewBadge,
};

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

class TestSuiteContainer extends React.Component {
  constructor(props) {
    super(props);
    const recentSuite = this.props.testSuites.find(
      (suite) =>
        suite.id ==
        localStorage.getItem(`project_${this.props.project.id}_recent_suite`)
    );
    this.state = {
      project: this.props.project,
      activeSuite:
        !(window.location.href.split("test_suites")[1].length > 1) &&
        recentSuite
          ? recentSuite
          : this.props.activeSuite,
      caseDragging: false,
      template: null,
      sideBarOpen: true,
      csvData: [],
      testSuites: this.props.testSuites,
      testSectionTemplates: this.props.testSectionTemplates,
      loading: false,
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
    this.badgeUrls = {
      test_cases_bronze:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Cases%20-%20Bronze.svg",
      test_cases_silver:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Cases%20-%20Silver.svg",
      test_cases_gold:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Cases%20-%20Gold.svg",
    };
    this.testSuiteSideMenu = React.createRef();
    this.rightSideBarListener = () => {
      if (window.pageYOffset > 106 && !this.rightSideBarMoved) {
        this.sideBarElem.style.paddingTop = "0px";
        this.rightSideBarMoved = true;
      } else if (window.pageYOffset <= 106) {
        this.sideBarElem.style.paddingTop = `${106 - window.pageYOffset}px`;
        this.rightSideBarMoved = false;
      }
    };
    this.rightSideBarMoved = false;
    this.createTestPass = this.createTestPass.bind(this);
  }

  componentDidMount() {
    if (this.props.new_badge) {
      this.setModal(true, "new-badge", {
        badgeDetails: this.props.new_badge.details,
        badgeName: this.props.new_badge.name,
        imageLink: this.badgeUrls[this.props.new_badge.identity],
        customClass: "new-badge-modal",
      });
    }
    Sentry.setUser({
      username: this.props.currentUser.name,
      id: this.props.currentUser.id,
    });
    Sentry.setTag("page", "test_cases");
    this.sideBarElem = document.getElementById("right-sidebar");
    if (window.pageYOffset > 106) {
      this.sideBarElem.style.paddingTop = "0px";
      this.rightSideBarMoved = true;
    }
    window.addEventListener("scroll", this.rightSideBarListener, {
      passive: true,
    });
    if (
      window.location.href.split("test_suites")[1].length > 1 &&
      this.state.activeSuite
    ) {
      localStorage.setItem(
        `project_${this.props.project.id}_recent_suite`,
        this.state.activeSuite.id
      );
    }

    if (this.state.activeSuite) {
      this.setCsvData(this.state.activeSuite.id);
    }
    // Add event listener for popstate event
    window.addEventListener("popstate", (e) => this.handlePopState(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSuite } = this.state;
    if (prevState.activeSuite.id !== activeSuite.id) {
      this.setCsvData(activeSuite.id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.rightSideBarListener);
    // Remove event listener when component is unmounted
    window.removeEventListener("popstate", this.handlePopState);
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  testCasesResponseToCsvData = (response) => {
    const stripHtmlToText = (html) => {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      let res = tmp.textContent || tmp.innerText || "";
      res.replace("\u200B", ""); // zero width space
      res = res.trim();
      return res;
    };

    const { project, activeSuite } = this.state;
    const data = [];

    data.push(["Project Name", "", project.name]);
    data.push(["Project Manager", "", project.owner.name]);
    data.push(["", "", ""]);
    data.push(["", "", ""]);
    data.push(["", "", ""]);
    // const activeSuite = testSuites.find((x) => x.id === activeSuite.id);
    // replace strips out html that maybe present in the steps
    data.push([activeSuite.name, "", ""]);
    data.push(["Steps", "Action", "Notes/Expected Result"]);
    Object.keys(response.data).forEach((testCase, caseIndex) => {
      const tc = response.data[testCase];
      data.push(["", tc.name, ""]);
      if (tc.test_steps.length >= 1) {
        Object.keys(tc.test_steps).forEach((testStep, stepIndex) => {
          const step = tc.test_steps[testStep];
          const stepText = stripHtmlToText(step.text).replace(
            /(<([^>]+)>)/gi,
            ""
          );
          const stepDetails = stripHtmlToText(step.details).replace(
            /(<([^>]+)>)/gi,
            ""
          );
          data.push([
            `${caseIndex + 1}.${(stepIndex + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`,
            stepText,
            stepDetails,
          ]);
        });
      }
    });

    return data;
  };

  setCsvData = (suiteId) => {
    const api = new ApiClient();
    api
      .get(`/test_suites/${suiteId}/test_cases.json`)
      .then((res) => {
        this.setState({ csvData: this.testCasesResponseToCsvData(res) });
      })
      .catch((err) => console.error(err));
  };

  handlePopState = (e) => {
    const projectId = e.target.location.pathname.split("/")[2];

    this.setState({ loading: false });

    window.history.pushState(
      "page2",
      "Test Platform",
      `/projects/${projectId}/test_suites`
    );

    this.setState({ loading: true });

    $.ajax({
      url: `/projects/${projectId}/test_suites`,
      dataType: "json",
      cache: false,
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success: (data) => {
        this.setState(
          {
            project: data.project,
            testSectionTemplates: data.testSectionTemplates,
            testSuites: data.testSuites,
            activeSuite: data.activeSuite,
            sideBarOpen:
              data.testSuites.length > 0 ? this.state.sideBarOpen : true,
            loading: false,
          },
          () => {
            const elem = document.getElementById("active-suite-title-input");
            if (elem && this.state.activeSuite) {
              elem.value = this.state.activeSuite.name;
            }
          }
        );
      },
      error: (status, err) => {
        console.error(`/projects/${projectId}/test_suites`, status, err);
      },
    });
  };

  deleteHandler = (e) => {
    const { dataset } = e.target;
    const self = this;
    let object;
    let url;
    if (dataset.tpid) {
      object = "Test Pass";
      url = `/test_passes/${dataset.tpid}.json`;
    } else if (dataset.tsid) {
      object = "Test Directory";
      url = `/test_suites/${dataset.tsid}.json`;
    }
    Swal.fire({
      title: `Delete ${object}`,
      text: `Are you sure that you want to delete this ${object}?`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
      // ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        self.setState({ loading: true });
        $.ajax({
          url,
          type: "DELETE",
          dataType: "json",
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success(data) {
            Object.prototype.hasOwnProperty.call(dataset, "tsid");
            if (
              dataset &&
              Object.prototype.hasOwnProperty.call(dataset, "tsid")
            ) {
              if (data.activeSuite.id == parseInt(dataset.tsid)) {
                window.history.pushState(
                  "page2",
                  "Test Platform",
                  `/projects/${data.project.id}/test_suites/`
                );
                if (data.suites.length > 0) {
                  localStorage.setItem(
                    `project_${data.project.id}_recent_suite`,
                    data.suites[0].id
                  );
                }
                self.setState(
                  {
                    project: data.project,
                    loading: false,
                    activeSuite: data.suites[0],
                    testSuites: data.suites,
                  },
                  () => {
                    const elem = document.getElementById(
                      "active-suite-title-input"
                    );
                    if (elem) {
                      elem.value = data.suites[0].name;
                    }
                  }
                );
              } else {
                window.history.pushState(
                  "page2",
                  "Test Platform",
                  `/projects/${data.project.id}/test_suites/`
                );
                if (data.activeSuite && data.activeSuite.id) {
                  localStorage.setItem(
                    `project_${data.project.id}_recent_suite`,
                    data.activeSuite.id
                  );
                }
                self.setState(
                  {
                    project: data.project,
                    loading: false,
                    activeSuite: data.activeSuite,
                    testSuites: data.suites,
                  },
                  () => {
                    const elem = document.getElementById(
                      "active-suite-title-input"
                    );
                    if (elem) {
                      elem.value = data.activeSuite.name;
                    }
                  }
                );
              }
            } else {
              self.setState({
                project: data.project,
                loading: false,
                testSuites: data.suites,
              });
            }
          },
          error(status, err) {
            console.error(url, status, err);
          },
        });
      } else if (result.dismiss) {
        console.log("dismissed");
      }
    });
  };

  activeSuiteHandler = (testSuite) => {
    this.setState({
      activeSuite: testSuite,
      loading: false,
    });
  };

  linkHandler = (id) => {
    this.setState({ loading: true });
    const projectId = this.state.project.id;
    $.ajax({
      url: `/test_suites/${id}`,
      dataType: "json",
      cache: false,
      beforeSend(xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success: function (data) {
        const { testSuite } = data;
        window.history.pushState(
          "page2",
          "Test Platform",
          `/projects/${projectId}/test_suites/${data.testSuite.id}`
        );
        this.setState(
          {
            activeSuite: testSuite,
            loading: false,
          },
          () => {
            const elem = document.getElementById("active-suite-title-input");
            if (elem) {
              elem.value = testSuite.name;
            }
          }
        );
      }.bind(this),
      error(status, err) {
        console.error(`/test_suites/${id}`, status, err);
      },
    });
  };

  casePositionHandler = (test_cases, promiseResolve) => {
    const suite = this.state.activeSuite;
    suite.test_cases = test_cases;
    this.setState({ activeSuite: suite, template: false }, () => {
      if (promiseResolve) {
        promiseResolve("done");
      }
    });
  };

  caseHandler = (tc, promiseResolve) => {
    try {
      const suite = this.state.activeSuite;
      const tcs = suite.test_cases;
      const tcIds = tcs.map((x) => x.id);
      if (tc.delete_key) {
        const index = tcs.map((e) => e.id).indexOf(tc.id);
        const new_array = tcs.filter((_, i) => i !== index);
        const newSuite = { ...suite, test_cases: new_array };
        this.setState({ activeSuite: newSuite, template: false }, () => {
          if (promiseResolve) {
            promiseResolve("done");
          }
        });
      } else {
        if (tcs.length > 0) {
          for (let i = 0; i < tcs.length; i++) {
            if (tcs[i].id == tc.id) {
              tcs.splice(i, 1, tc);
              break;
            } else if (!tcIds.includes(tc.id)) {
              tcs.push(tc);
              break;
            }
          }
        } else {
          tcs.push(tc);
        }
        this.setState({ activeSuite: suite, template: false }, () => {
          if (promiseResolve) {
            promiseResolve("done");
          }
        });
      }
    } catch (err) {
      err.message = `Case Handler Error Frontend: ${err.message}`;
      Sentry.captureException(err);
      console.error(err);
    }
  };

  suiteHandler = (testSuite) => {
    try {
      const ids = this.state.testSuites.map((x) => x.id);
      if (this.state.testSuites.includes(testSuite)) {
        this.setState({ activeSuite: testSuite });
      } else if (ids.includes(testSuite.id)) {
        var suites = this.state.testSuites;
        for (let i = 0; i < suites.length; i++) {
          if (suites[i].id == testSuite.id) {
            suites.splice(i, 1, testSuite);
          }
        }
        this.setState(
          { activeSuite: testSuite, suites: this.state.suites },
          () => {
            const elem = document.getElementById("active-suite-title-input");
            if (elem) {
              elem.value = testSuite.name;
            }
          }
        );
      } else {
        const { testSuites } = this.state;
        testSuites.push(testSuite);
        var suites = testSuites.sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
        this.setState({ activeSuite: testSuite, testSuites: suites }, () => {
          const elem = document.getElementById("active-suite-title-input");
          if (elem) {
            elem.value = testSuite.name;
          }
        });
      }
    } catch (err) {
      err.message = `Test Suite Handler Error Frontend: ${err.message}`;
      Sentry.captureException(err);
      console.error(err);
    }
  };

  setLoading = (option) => {
    this.setState({ loading: option });
  };

  async createTestPass() {
    if (this.state.activeSuite.id) {
      const response = await axios.get(
        `/test_suites/${this.state.activeSuite.id}.json`
      );

      if (
        !response.data.testSuite.test_cases.find((s) => s.test_steps.length > 0)
      ) {
        M.toast({
          html: "You need at least one test case to create a test pass",
          displayLength: 5000,
          classes: "red",
        });
        return null;
      }

      this.setModal(true, "new-test-pass", {
        newPass: true,
        testSuiteId: this.state.activeSuite.id,
        customClass: "new-test-pass-modal new-scrollbar",
        testSuiteName: this.state.activeSuite.name,
        projectId: this.state.project.id,
        currentUserId: this.props.currentUser.id,
      });
    }
  }

  setCaseDragging = (bool, template) => {
    this.setState({ caseDragging: bool, template });
  };

  handleTestSectionTemplates = (testSectionTemplates) => {
    this.setState({ testSectionTemplates, loading: false });
  };

  handleSideMenuAdd = () => {
    if (this.testSuiteSideMenu && this.testSuiteSideMenu.current) {
      this.testSuiteSideMenu.current.handleParentNewSuite();
    }
  };

  measureText = (txt) => {
    this.element = this.element || document.createElement("canvas");
    this.context = this.element.getContext("2d");
    this.context.font = "14px Arial";
    const { width } = this.context.measureText(txt);
    if (width > 215) {
      return `${((215 - (width - 215)) / 215) * 10 + 4}px`;
    }
    return "14px";
  };

  render() {
    return (
      <div id="tsContainer">
        <div
          className={`modal-backdrop-loader ${
            this.state.loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {this.state.loading && <div id="test-step-bugs-loader" />}
        </div>
        <span
          role="heading"
          aria-level="1"
          className="large-title"
          style={{
            display: "block",
            backgroundColor: "white",
            position: "relative",
            maxWidth: `calc(100% - ${100}px)`,
            marginLeft: "auto",
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            padding: "20px 0px 20px 20px",
            zIndex: "10",
            marginTop: "-5px",
            borderBottom: "solid 1px lightgray",
            marginBottom: "18px",
          }}
        >
          <img
            alt="filled testcase icon"
            style={{
              display: "inline-block",
              height: "50px",
              marginRight: "10px",
              verticalAlign: "-16px",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Cases%20-%20Filled2.svg"
          />
          Test Cases
          <ExportCSVButton
            data={this.state.csvData}
            filename={`${
              this.state.activeSuite
                ? _.snakeCase(this.state.activeSuite.name)
                : "default_name"
            }_export.csv`}
            $disabled={
              this.state.activeSuite === null ||
              this.state.activeSuite === undefined
            }
            target="_blank"
          >
            Export Test Directory
          </ExportCSVButton>
        </span>
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            id="testSuiteContainer"
            style={{
              display: "flex",
              width: "auto",
              minHeight: "calc(100vh - 223px)",
              margin: "10px 0 -40px 0px",
              transform: this.state.sideBarOpen
                ? "translateX(0px)"
                : "translateX(0px)",
              position: "relative",
              transition: "transform .45s ease-out, max-width .45s ease-out",
              WebkitTransition:
                "transform .45s ease-out, max-width .45s ease-out",
            }}
          >
            <div
              id="leftSuite"
              style={{
                flex: 1,
                flexBasis: "40%",
                padding: "0px 10px 10px 30px",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "40%",
                }}
              >
                <div style={{ display: "block", width: "calc(100% - 305px)" }}>
                  {this.state.activeSuite ? (
                    <TestSuiteForm
                      handleTestSectionTemplates={
                        this.handleTestSectionTemplates
                      }
                      setActiveSuite={this.activeSuiteHandler}
                      template={this.state.template}
                      setCaseDragging={this.setCaseDragging}
                      caseDragging={this.state.caseDragging}
                      setLoading={this.setLoading}
                      deleteHandler={this.deleteHandler}
                      createTestPass={this.createTestPass}
                      testSuite={this.state.activeSuite}
                      loading={this.state.loading}
                      casePositionHandler={this.casePositionHandler}
                      caseHandler={this.caseHandler}
                      suiteHandler={this.suiteHandler}
                    />
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "80px" }}>
                      <div>
                        <img
                          alt="empty icon"
                          style={{ height: "140px" }}
                          src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Cases%20-%20Empty%20State%20(1).svg"
                        />
                      </div>
                      <div style={{ marginTop: "-10px" }}>
                        <span style={{ fontWeight: "600", fontSize: "12pt" }}>
                          No Test Directories found.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            maxWidth: "534px",
                            fontSize: "10pt",
                            margin: "10px 0px 14px 0px",
                            lineHeight: "22px",
                          }}
                        >
                          {`Looks this project doesn't have any test directories
                          yet. When you create a new test directory, it can be
                          selected from the `}
                          <span style={{ color: "#519acc" }}>
                            Directory Menu
                          </span>
                          . Click the button below to get started.
                        </span>
                      </div>
                      <div>
                        <button
                          style={{
                            width: "160px",
                            fontSize: "10pt",
                            height: "35px",
                          }}
                          onClick={this.handleSideMenuAdd}
                          className="common-button-submit"
                        >
                          Create a Test Directory
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ReactModalV2
            isShowing={this.state.modalOpen}
            id="react-modal"
            page={this.state.currentModal}
            data={this.state.modalData}
            modalAction={this.setModal}
          />
        </div>
        <TestSuiteSideMenu
          measureText={this.measureText}
          ref={this.testSuiteSideMenu}
          handleTestSectionTemplates={this.handleTestSectionTemplates}
          template={this.state.template}
          caseHandler={this.caseHandler}
          casePositionHandler={this.casePositionHandler}
          setCaseDragging={this.setCaseDragging}
          testSectionTemplates={this.state.testSectionTemplates}
          key="test-suite-side-menu"
          project={this.state.project}
          linkHandler={this.linkHandler}
          suiteHandler={this.suiteHandler}
          setLoading={this.setLoading}
          activeSuite={this.state.activeSuite}
          testSuites={this.state.testSuites}
        />
      </div>
    );
  }
}

const ExportCSVButton = styled(CSVLink)`
  display: ${({ $disabled }) => ($disabled ? "none" : "inline-block")};
  float: right;
  margin-right: 20px;
  position: relative;
  top: 12px;
  font-size: 13px;
  appearance: button;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #519acc;
  cursor: pointer;
  border: none;
  color: #fff;
  font-weight: 400;
  height: 38px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1883ca;
  }
`;

TestSuiteContainer.propTypes = {
  // TODO: this is garbage, make real prop typing
  template: PropTypes.bool,
  project: PropTypes.object,
  currentUser: PropTypes.object,
  activeSuite: PropTypes.object,
  testCases: PropTypes.array,
  testSuite: PropTypes.object,
  testSuites: PropTypes.array,
  testSectionTemplates: PropTypes.array,
  linkHandler: PropTypes.func,
  setLoading: PropTypes.func,
  setCaseDragging: PropTypes.func,
  new_badge: PropTypes.bool,
};

export default TestSuiteContainer;
