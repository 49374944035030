import React from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import ErrorBoundary from "../../common/error_boundary";

class TestSuiteSideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      newSuite: false,
      newSuiteName: "",
      suiteCopy: null,
    };
    this.suiteName = React.createRef();
    this.handleOutsideClick = (e) => {
      var elem = document.getElementById(`test-suite-list-container`);
      if (elem) {
        if (!elem.contains(e.target)) {
          this.handleNewSuite();
        }
      } else {
        window.removeEventListener("mousedown", this.handleOutsideClick);
      }
    };
    this.scrollOpacity = this.scrollOpacity.bind(this);
    this.checkSuiteList = this.checkSuiteList.bind(this);
    this.checkTemplateList = this.checkTemplateList.bind(this);
    this.canvas = document.createElement("canvas").getContext("2d");
  }

  handleSuiteClick = (e) => {
    var id = e.target.dataset.id;
    localStorage.setItem(`project_${this.props.project.id}_recent_suite`, id);
    this.props.linkHandler(id);
  };

  handleSearch = (e) => {
    var text = e.target.value;
    this.setState({ search: text });
  };

  handleParentNewSuite = () => {
    if (!this.state.newSuite) {
      this.setState({ newSuite: true }, () => {
        window.addEventListener("mousedown", this.handleOutsideClick);
      });
    }
  };

  handleNewSuite = () => {
    if (this.state.newSuite) {
      window.removeEventListener("mousedown", this.handleOutsideClick);
      this.setState({ newSuite: false, newSuiteName: "", suiteCopy: null });
    } else {
      this.setState({ newSuite: true }, () => {
        window.addEventListener("mousedown", this.handleOutsideClick);
      });
    }
  };

  createNewSuite = () => {
    this.props.setLoading(true);
    var projectId = this.props.project.id;
    var data = {
      test_suite: { name: this.suiteName.current.value, project_id: projectId },
    };
    $.ajax({
      url: "/projects/" + projectId + "/test_suites.json",
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        window.removeEventListener("mousedown", this.handleOutsideClick);
        this.props.setLoading(false);
        Swal.fire({
          title: "Test Directory",
          text: "Test Directory Created",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
          this.props.suiteHandler(data);
          this.handleNewSuite();
          localStorage.setItem(
            `project_${data.project_id}_recent_suite`,
            data.id
          );
          window.history.pushState(
            "page3",
            "Test Platform",
            "/projects/" + data.project_id + "/test_suites/" + data.id
          );
          this.checkSuiteList();
          window.location.reload();
        });
      }.bind(this),
      error: function (status, err) {
        this.props.setLoading(false);
        console.error("/test_pass/build_options", status, err);
      }.bind(this),
    });
  };

  copyTestSuite = () => {
    this.props.setLoading(true);
    var data = { test_suite: { name: this.suiteName.current.value } };
    var id = this.state.suiteCopy;
    $.ajax({
      url: "/test_suites/" + id + "/copy.json",
      data: data,
      type: "POST",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        Swal.fire({
          title: "Test Directory Copied",
          text: "Successfully Copied",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(
          function () {
            localStorage.setItem(
              `project_${data.project_id}_recent_suite`,
              data.id
            );
            window.history.pushState(
              "page3",
              "Test Platform",
              "/projects/" + data.project_id + "/test_suites/" + data.id
            );
            this.props.suiteHandler(data);
            this.props.setLoading(false);
            this.setState({ add: false });
            this.checkSuiteList();
          }.bind(this),
          function (dismiss) {
            localStorage.setItem(
              `project_${data.project_id}_recent_suite`,
              data.id
            );
            window.history.pushState(
              "page3",
              "Test Platform",
              "/projects/" + data.project_id + "/test_suites/" + data.id
            );
            this.props.suiteHandler(data);
            this.props.setLoading(false);
            // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
          }.bind(this)
        );
      }.bind(this),
      error: function (status, err) {
        console.error("/test_pass/build_options", status, err);
      }.bind(this),
    });
  };

  handleNewSuiteChange = (e) => {
    var text = e.target.value;
    this.setState({ newSuiteName: text });
  };

  selectSuiteCopy = (e) => {
    var self = this;
    var id = parseInt(e.target.dataset.id);
    self.setState({ suiteCopy: self.state.suiteCopy === id ? null : id });
    if (self.suiteName && self.suiteName.current) {
      self.suiteName.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setTimeout(() => {
        self.suiteName.current.focus();
      }, 200);
    }
  };

  dragStart = (e) => {
    var id = e.target.dataset.id;
    this.props.setCaseDragging(true, id);
  };

  dragEnd = (e) => {
    var id = e.target.dataset.id;
    if (e.dataTransfer.dropEffect === "none") {
      this.props.setCaseDragging(false, false);
    } else {
      this.props.setCaseDragging(false, id);
    }
  };

  dragOver = (e) => {};

  dragDrop = (e) => {
    if (e.target.id === "template-drag-hover" && this.props.template) {
      var elem = document.getElementById("template-drag-hover-top");
      elem.style.height = "0px";
      this.handleCaseAdd();
      return;
    }
  };

  handleCaseAdd = () => {
    this.props.setLoading(true);
    var data;
    data = { test_case: { test_section_template_id: this.props.template } };
    var self = this;
    var id = this.props.activeSuite.id;
    var url = "/test_suites/" + id + "/test_cases.json";
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        var casePromise = new Promise((resolve, reject) => {
          self.props.caseHandler(data, resolve);
          self.checkSuiteList;
        });
        casePromise.then(() => {
          self.props.setLoading(false);
        });
      }.bind(this),
      error: function (err) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        console.error(url, err);
      }.bind(this),
    });
  };

  deleteTemplate = (e) => {
    var self = this;
    var id = e.target.dataset.id;
    var url = `/test_section_templates/${id}.json`;
    var data = { test_suite_id: this.props.activeSuite.id };
    Swal.fire({
      title: "Delete",
      text: `Are you sure you would like to delete this Test Section Template?`,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
      //ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        self.props.setLoading(true);
        $.ajax({
          url: url,
          type: "DELETE",
          data: data,
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          dataType: "json",
          cache: false,
          success: function (data) {
            M.toast({
              html: `Test Section Template deleted`,
              displayLength: 3000,
              classes: "green",
            });
            self.props.handleTestSectionTemplates(data.templates);
            self.props.casePositionHandler(data.test_cases);
            self.checkTemplateList();
          }.bind(this),
          error: function (status, err) {
            self.props.setLoading(false);
            console.error(url, status, err);
          }.bind(this),
        });
      } else if (result.dismiss) {
        console.log("dismissed");
      }
    });
  };

  resizeText = (txt, maxWidth, fontSize) => {
    // canvas created in constructor
    // this.canvas = document.createElement("canvas").getContext("2d");
    this.canvas.font = `${fontSize}px Arial`;
    var width = this.canvas.measureText(txt).width;
    var minFontSize = 8;
    if (width > maxWidth) {
      var newfontSize = fontSize;
      var decrement = 1;
      var newWidth;
      while (width > maxWidth) {
        newfontSize -= decrement;
        if (newfontSize < minFontSize) {
          return { fontSize: `${minFontSize}px` };
        }
        this.canvas.font = `${newfontSize}px Arial`;
        newWidth = this.canvas.measureText(txt).width;
        if (newWidth < maxWidth && decrement === 1) {
          decrement = 0.1;
          newfontSize += 1;
        } else {
          width = newWidth;
        }
      }
      return { fontSize: `${newfontSize}px` };
    } else {
      return { fontSize: `${fontSize}px` };
    }
  };

  onScroll(e) {
    var elem = e.target;
    if (
      elem &&
      elem.previousElementSibling &&
      elem.nextElementSibling &&
      elem.scrollHeight - elem.clientHeight != 0
    ) {
      if (elem.scrollTop === elem.scrollHeight - elem.clientHeight) {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "0";
      } else if (elem.scrollTop === 0) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "1";
      } else {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "1";
      }
    }
  }

  scrollOpacity(elem) {
    if (elem) {
      if (elem.scrollHeight > elem.clientHeight) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "1";
      } else if (elem.scrollHeight < elem.clientHeight) {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "0";
      } else {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "0";
      }
    }
  }

  checkSuiteList() {
    var elemOne = document.getElementById("test-suite-list");
    this.scrollOpacity(elemOne);
  }

  checkTemplateList() {
    var elemTwo = document.getElementById("section-template-list");
    this.scrollOpacity(elemTwo);
  }

  componentDidMount() {
    var elemOne = document.getElementById("test-suite-list");
    this.scrollOpacity(elemOne);
    var elemTwo = document.getElementById("section-template-list");
    this.scrollOpacity(elemTwo);
    window.addEventListener("resize", this.checkTemplateList);
    window.addEventListener("resize", this.checkSuiteList);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.newSuite == true &&
      this.state.newSuite != prevState.newSuite
    ) {
      var elem = document.getElementById("new-suite-input-container");
      if (elem) {
        elem.children[0].focus();
      }
    } else if (this.props.project.id != prevProps.project.id) {
      this.checkTemplateList();
      this.checkSuiteList();
    } else if (this.props.testSuites.length != prevProps.testSuites.length) {
      this.checkSuiteList();
    } else if (
      this.props.testSectionTemplates.length !=
      prevProps.testSectionTemplates.length
    ) {
      this.checkTemplateList();
    }
  }

  onListEnter(e) {
    if (e && e.target) {
      var elem = document.getElementById("test-suite-list");
      if (elem) {
        elem.style.overflow = "hidden auto";
        elem.previousElementSibling.style.width = "280px";
        elem.nextElementSibling.style.width = "280px";
      }
    }
  }

  onListExit(e) {
    if (e && e.target) {
      var elem = document.getElementById("test-suite-list");
      if (elem) {
        elem.style.overflow = "hidden hidden";
        elem.previousElementSibling.style.width = "290px";
        elem.nextElementSibling.style.width = "290px";
      }
    }
  }

  onTemplateListEnter(e) {
    if (e && e.target) {
      var elem = document.getElementById("section-template-list");
      if (elem) {
        elem.style.overflow = "hidden auto";
        elem.previousElementSibling.style.width = "280px";
        elem.nextElementSibling.style.width = "280px";
      }
    }
  }

  onTemplateListExit(e) {
    if (e && e.target) {
      var elem = document.getElementById("section-template-list");
      if (elem) {
        elem.style.overflow = "hidden hidden";
        elem.previousElementSibling.style.width = "290px";
        elem.nextElementSibling.style.width = "290px";
      }
    }
  }

  render() {
    var startingSuites = this.props.testSuites ? this.props.testSuites : [];
    var suites = startingSuites;
    if (this.state.search) {
      suites = startingSuites.filter((s) =>
        s.name.toLowerCase().includes(this.state.search.toLowerCase())
      );
    }
    return (
      <ErrorBoundary>
        <section
          role="group"
          id="right-sidebar"
          style={{
            display: "block",
            position: "fixed",
            top: "0px",
            paddingTop: "103px",
            width: "320px",
            height: "100vh",
            right: "0px",
            zIndex: "9",
            borderLeft: "solid 1px lightgray",
          }}
        >
          <div aria-level="2" style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                top: "4px",
                right: "28px",
                color: "#ababab",
              }}
              className="material-icons prefix"
            >
              search
            </i>
            <input
              aria-live="assertive"
              aria-label={
                this.state.search != "" && suites.length >= 1
                  ? `${suites.length} suites found for searchterm ${this.state.search}`
                  : this.state.search != "" && suites.length == 0
                  ? `No results for search team ${this.state.search}`
                  : "Search for directory"
              }
              maxLength={30}
              onChange={this.handleSearch}
              id="directory-search-input"
              style={{
                width: "276px",
                display: "block",
                margin: "25px auto 25px 24px",
                paddingRight: "30px",
              }}
              placeholder="Search for directory"
              type="text"
              className="common-input browser-default"
            />
          </div>
          <div
            id="test-suite-list-container"
            onMouseEnter={this.onListEnter}
            onMouseLeave={this.onListExit}
            style={{ height: "calc(50% - 46px)", overflow: "hidden hidden" }}
            className="new-scrollbar"
          >
            <p
              className="small-title"
              style={{ margin: "20px 20px 10px 24px" }}
            >
              Test Directories
            </p>
            {!this.state.newSuite ? (
              <button
                aria-label="New Test Directory"
                onClick={this.handleNewSuite}
                style={{
                  margin: "-4px 0px 5px 24px",
                  display: "block",
                  fontSize: "10pt",
                  width: "130px",
                  padding: "0px",
                  lineHeight: "20px",
                }}
                className="light-button"
              >
                <span
                  className="material-icons"
                  style={{ verticalAlign: "text-top", fontSize: "16px" }}
                >
                  add
                </span>
                New Test Directory
              </button>
            ) : (
              <div id="new-suite-input-container">
                <input
                  onChange={this.handleNewSuiteChange}
                  onKeyDown={this.nameHandler}
                  style={{
                    width: "276px",
                    margin: "0px 20px 0px 24px",
                    fontSize: "10pt",
                  }}
                  placeholder="New Test Directory Name"
                  type="text"
                  className="common-input browser-default"
                  ref={this.suiteName}
                  maxLength={40}
                  required
                />
                <button
                  disabled={!this.state.newSuiteName}
                  id="suite-create-button"
                  className="light-button"
                  style={{
                    fontSize: "10pt",
                    fontWeight: "600",
                    paddingLeft: "24px",
                  }}
                  onClick={
                    this.state.suiteCopy
                      ? this.copyTestSuite
                      : this.createNewSuite
                  }
                >
                  {this.state.suiteCopy ? "Copy" : "Create"}
                </button>
                <button
                  className="light-button"
                  style={{ fontSize: "10pt", fontWeight: "600" }}
                  onClick={this.handleNewSuite}
                >
                  Cancel
                </button>
              </div>
            )}
            <div style={{ left: "23px" }} className="top-tb-blur"></div>
            <div
              aria-label="List of other test suites"
              role="list"
              onScroll={this.onScroll}
              id="test-suite-list"
              className="new-scrollbar"
              style={{
                marginTop: "-30px",
                paddingTop: "10px",
                maxHeight: "calc(100% - 105px)",
                paddingBottom: "20px",
              }}
            >
              {suites.length > 0 ? (
                suites.map((suite, index) => {
                  var active =
                    this.props.activeSuite &&
                    this.props.activeSuite.id === suite.id;
                  return (
                    <div
                      role="listitem"
                      aria-level="2"
                      key={`suite-link-container${suite.id}`}
                      className="suite-link-container"
                      style={{
                        marginLeft: "24px",
                        marginRight: "5px",
                        cursor: "pointer",
                        position: "relative",
                        lineHeight: "50px",
                        borderBottom:
                          index !== suites.length - 1
                            ? "solid 1px lightgray"
                            : "none",
                      }}
                    >
                      <button
                        aria-label={`Select ${suite.name}`}
                        className="div-btn-overlay"
                        onClick={
                          this.state.newSuite
                            ? this.selectSuiteCopy
                            : this.handleSuiteClick
                        }
                        data-id={suite.id}
                      ></button>
                      {this.state.newSuite ? (
                        <div
                          className="common-radio"
                          style={{
                            padding: "10px 0px",
                            pointerEvents: "none",
                            display: "inline-block",
                            height: "43px",
                            lineHeight: "30px",
                            verticalAlign: "-7px",
                            width: "25px",
                            marginLeft: "20px",
                          }}
                        >
                          <label>
                            <input
                              style={{ width: "100px" }}
                              onChange={this.selectSuiteCopy}
                              checked={this.state.suiteCopy === suite.id}
                              className="with-gap"
                              name="group1"
                              type="radio"
                            />
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "11pt",
                                color: "#242424",
                                marginRight: "20px",
                                paddingLeft: "28px",
                              }}
                            ></span>
                          </label>
                        </div>
                      ) : (
                        [
                          <img
                            alt="test directory icon"
                            key={`hover-suite-icon-${suite.id}`}
                            className="hover-suite-icon"
                            style={{
                              width: "19px",
                              display: "block",
                              verticalAlign: "sub",
                              position: "absolute",
                              left: "20px",
                              top: "15px",
                              pointerEvents: "none",
                            }}
                            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test_Directory_White.svg"
                          />,
                          active ? (
                            <img
                              alt="blue test directory icon"
                              key={`hover-suite-icon-${suite.id}-2`}
                              className="non-hover-suite-icon"
                              style={{
                                width: "19px",
                                display: "inline-block",
                                verticalAlign: "sub",
                                margin: "0px 10px 0px 20px",
                                pointerEvents: "none",
                              }}
                              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test_Directory_Blue.svg"
                            />
                          ) : (
                            <img
                              alt="black test directory icon"
                              key={`hover-suite-icon-${suite.id}-3`}
                              className="non-hover-suite-icon"
                              style={{
                                width: "19px",
                                display: "inline-block",
                                verticalAlign: "sub",
                                margin: "0px 10px 0px 20px",
                                pointerEvents: "none",
                              }}
                              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Directory%20Black_FullSize.svg"
                            />
                          ),
                        ]
                      )}
                      <span
                        style={{
                          color: active ? "#519acc" : "#242424",
                          pointerEvents: "none",
                          ...this.resizeText(suite.name, 210, 14),
                        }}
                      >
                        {suite.name}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div>
                  {startingSuites.length > 0 && (
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                      <img
                        alt="empty testcase icon"
                        style={{ height: "100px" }}
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Cases%20-%20Empty%20State%20(1).svg"
                      />
                      <span style={{ display: "block", fontWeight: "600" }}>
                        No results found for{" "}
                        <span
                          style={{ color: "#519acc" }}
                        >{`'${this.state.search}'`}</span>
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div style={{ left: "23px" }} className="bottom-tb-blur"></div>
          </div>
          {startingSuites.length > 0 && (
            <div
              id="section-templete-conatiner"
              onMouseEnter={this.onTemplateListEnter}
              onMouseLeave={this.onTemplateListExit}
              style={{ height: "calc(50% - 51px)", paddingTop: "20px" }}
            >
              <p
                aria-level="1"
                role="heading"
                title="SECTION TEMPLATES"
                className="small-title"
                style={{ margin: "0px 20px 0px 24px" }}
              >
                Section Templates
              </p>
              <div style={{ left: "23px" }} className="top-tb-blur"></div>
              <div
                aria-hidden="true"
                tabIndex="-1"
                onScroll={this.onScroll}
                aria-label="List of test section templates"
                role="list"
                id="section-template-list"
                className="new-scrollbar"
                style={{
                  paddingTop: "10px",
                  marginTop: "-30px",
                  maxHeight: `calc(100% - 48px)`,
                  overflow: "hidden",
                }}
              >
                {this.props.testSectionTemplates.map((template, index) => {
                  return (
                    <div
                      aria-hidden="true"
                      key={template.id}
                      draggable
                      data-id={template.id}
                      onDragStart={this.dragStart}
                      onDragOver={this.dragOver}
                      onDragEnd={this.dragEnd}
                      className="suite-link-container"
                      style={{
                        marginLeft: "24px",
                        marginRight: "5px",
                        cursor: "grab",
                        position: "relative",
                        lineHeight: "50px",
                        borderBottom:
                          index !== this.props.testSectionTemplates.length - 1
                            ? "solid 1px lightgray"
                            : "none",
                      }}
                    >
                      <img
                        alt="white pen icon"
                        className="hover-suite-icon"
                        style={{
                          width: "23px",
                          display: "block",
                          verticalAlign: "sub",
                          position: "absolute",
                          left: "18px",
                          top: "12px",
                          pointerEvents: "none",
                        }}
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Paper-Pen-white.svg"
                      />
                      <img
                        alt="black pen icon"
                        className="non-hover-suite-icon"
                        style={{
                          width: "23px",
                          display: "inline-block",
                          verticalAlign: "-5px",
                          margin: "0px 10px 0px 18px",
                          pointerEvents: "none",
                        }}
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Paper-Pen.svg"
                      />
                      <span
                        style={{
                          color: "#242424",
                          fontSize: this.props.measureText(template.title),
                          pointerEvents: "none",
                        }}
                      >
                        {template.title}
                      </span>
                      <button
                        aria-hidden="true"
                        tabIndex="-1"
                        onClick={this.deleteTemplate}
                        className="i-btn-wrapper"
                      >
                        <i
                          data-id={template.id}
                          className="material-icons"
                          style={{
                            display: "block",
                            position: "absolute",
                            opacity: "0",
                            right: "5px",
                            top: "12px",
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          delete
                        </i>
                      </button>
                    </div>
                  );
                })}
              </div>
              <div style={{ left: "23px" }} className="bottom-tb-blur"></div>
            </div>
          )}
        </section>
      </ErrorBoundary>
    );
  }
}
export default TestSuiteSideMenu;

TestSuiteSideMenu.propTypes = {
  template: PropTypes.bool,
  project: PropTypes.object,
  activeSuite: PropTypes.object,
  testCases: PropTypes.array,
  testSuite: PropTypes.object,
  testSuites: PropTypes.array,
  testSectionTemplates: PropTypes.array,
  linkHandler: PropTypes.func,
  measureText: PropTypes.func,
  suiteHandler: PropTypes.func,
  setLoading: PropTypes.func,
  setCaseDragging: PropTypes.func,
};
