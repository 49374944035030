import React from "react";
import ProgressRing from "../../common/progress_ring";

const URL_PREFIX =
  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform";
const DEVICE_ICON_URLS = [
  URL_PREFIX,
  `${URL_PREFIX}/Icons_Desktop.svg`,
  `${URL_PREFIX}/Icons_Desktop.svg`,
  {
    Mobile: `${URL_PREFIX}/Icons_Mobile.svg`,
    Tablet: `${URL_PREFIX}/Icons_Tablet.svg`,
  },
  {
    Mobile: `${URL_PREFIX}/Icons_Mobile.svg`,
    Tablet: `${URL_PREFIX}/Icons_Tablet.svg`,
  },
  `${URL_PREFIX}/Icons_Laptop.svg`,
  `${URL_PREFIX}/VR_AR.svg`,
];

class TestPassLandingDevice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      testDevice: this.props.testDevice,
      totalSteps: Object.values(this.props.testDevice.device_progress).reduce(
        (a, b) => a + b,
        0
      ),
    };
    this.getDeviceImage = this.getDeviceImage.bind(this);
  }

  getDeviceImage() {
    var testDevice = this.props.testDevice;
    if (testDevice.device_platform === 3) {
      return DEVICE_ICON_URLS[testDevice.device_platform][
        testDevice.title.toLowerCase().includes("ipad") ? "Tablet" : "Mobile"
      ];
    } else if (testDevice.device_platform === 4) {
      var tabStrings = ["tab", "nexus", "pixel c"];
      return DEVICE_ICON_URLS[testDevice.device_platform][
        tabStrings.some((v) => testDevice.title.toLowerCase().includes(v))
          ? "Tablet"
          : "Mobile"
      ];
    } else {
      return DEVICE_ICON_URLS[testDevice.device_platform];
    }
  }

  render() {
    var totalSteps = Object.values(
      this.props.testDevice.device_progress
    ).reduce((a, b) => a + b, 0);
    var testDevice = this.props.testDevice;
    return (
      <div
        style={{
          borderRadius: "6px",
          position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.37) 0px 0px 4px",
          whiteSpace: "nowrap",
          margin: this.props.index == 0 ? "20px 20px 15px 20px" : "15px 20px",
          padding: "5px 15px",
        }}
      >
        <div
          className="pass-landing-device"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ display: "inline-block", textAlign: "left" }}>
            <div
              style={{
                display: "inline-block",
                margin: "0px 35px 0px 25px",
                position: "relative",
              }}
            >
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "45px",
                  textAlign: "center",
                  fontSize: "10pt",
                  left: "3px",
                  top: "12px",
                  fontWeight: "600",
                }}
              >
                {testDevice.percent}%
              </span>
              <ProgressRing
                radius={25}
                relative={true}
                stroke={5}
                progress={testDevice.percent}
                phantomRing={true}
                verticalAlign={"-6px"}
              />
            </div>
            <img
              style={{
                display: "inline-block",
                width: "40px",
                marginRight: "30px",
                verticalAlign: "-1px",
              }}
              src={this.getDeviceImage()}
            />
            <div
              style={{
                display: "inline-block",
                width: "calc(100% - 134px)",
                verticalAlign: "0px",
              }}
            >
              <div style={{ display: "inline-block", width: "50%" }}>
                <div style={{ lineHeight: "18px" }}>
                  <span
                    style={{
                      fontSize: "8pt",
                      color: "#b7b7b7",
                      paddingLeft: "5px",
                      fontWeight: "600",
                    }}
                  >
                    DEVICE
                  </span>
                </div>
                <div
                  style={{
                    lineHeight: "18px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ fontSize: "10pt", paddingLeft: "5px" }}>
                    {testDevice.title}
                  </span>
                </div>
              </div>
              <div style={{ display: "inline-block", width: "50%" }}>
                <div style={{ lineHeight: "18px" }}>
                  <span
                    style={{
                      fontSize: "8pt",
                      color: "#b7b7b7",
                      paddingLeft: "5px",
                      fontWeight: "600",
                    }}
                  >
                    ASSIGNED TO
                  </span>
                </div>
                <div
                  style={{
                    lineHeight: "18px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ fontSize: "10pt", paddingLeft: "5px" }}>
                    {testDevice.assignee}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "inline-block", position: "relative" }}>
            <div>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                  height: "12px",
                  border: "solid 1px gray",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    position: "relative",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[1] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "#9ddea8",
                  }}
                  data-color="#7a9e80"
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[2] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "#c3552b",
                  }}
                  data-color="#c3552b"
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[3] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "#f5ee89",
                  }}
                  data-color="#c5bc3e"
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[4] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "#519bcc",
                  }}
                  data-color="#519bcc"
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[5] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "lavender",
                  }}
                  data-color="#9c9cda"
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "inline-flex",
                    width: `${
                      (testDevice.device_progress[0] / this.state.totalSteps) *
                      100
                    }%`,
                    justifyContent: "center",
                    verticalAlign: "top",
                    backgroundColor: "lightgray",
                  }}
                  data-color="#afafaf"
                ></div>
              </div>
              <span style={{ fontWeight: "600", paddingLeft: "10px" }}>
                {testDevice.device_progress[1] +
                  testDevice.device_progress[2] +
                  testDevice.device_progress[3] +
                  testDevice.device_progress[4] +
                  testDevice.device_progress[5]}{" "}
                / {totalSteps}
              </span>
            </div>
            <div
              style={{
                display: "inline-flex",
                textAlign: "center",
                justifyContent: "space-between",
                fontSize: "10pt",
                lineHeight: "20px",
              }}
            >
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[1]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Pass</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[2]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Fail</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[3]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Blocked</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[4]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Retest</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[5]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>N/A</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{testDevice.device_progress[0]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Untested</span>
              </div>
              <span
                style={{
                  fontWeight: "600",
                  paddingLeft: "10px",
                  lineHeight: "33px",
                }}
              >
                {testDevice.device_progress[1] +
                  testDevice.device_progress[2] +
                  testDevice.device_progress[3] +
                  testDevice.device_progress[4] +
                  testDevice.device_progress[5]}{" "}
                / {totalSteps}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TestPassLandingDevice;
