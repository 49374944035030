import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import styled from "styled-components";
import BasicSelect from "@components/common/basic_select";
import Timer from "@components/common/timer";
import "react-dates/initialize";
import { CalendarIcon } from "@components/common/icons";
import RoundDropdown from "@components/common/round_dropdown";
import { validateEmail } from "@utils/EmailValidation";
import ModalHeader from "./common/modal_header";

class EodReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      automation: false,
      projectTextInput: "",
      selectedProject: null,
      bugs: [],
      otherBugs: [],
      bugLinksInput: "",
      otherBugLinksInput: "",
      reopenedBugs: [],
      reopenedBugLinksInput: "",
      managerEmailInput: "",
      autoManagerEmailInput: false,
      selectedManager: null,
      summaryTextInput: "",
      auto: true,
      bugsTotalInput: 0,
      updatedBugsInput: 0,
      testRunsInput: 0,
      sendSelfCopy: true,
      automationFeatureInput: "",
      hoursInput: 0,
      testStepsInput: 0,
      singlePickerFocus: false,
      managerOptions: [],
      projectOptions: [],
      selectedTasks: [],
      selectedPlatforms: [],
      taskOptions: [],
      platformOptions: [],
      reportDate: moment(),
    };
    this.timer = null;
  }

  componentDidMount() {
    const { setBackdropLoader, data } = this.props;
    const { currentProject, currentUserId } = data;
    document.body.style.overflow = "hidden";
    setBackdropLoader(true);
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]') // eslint-disable-line quotes
        .getAttribute("content"),
    };
    axios
      .get(`/eod_projects/${currentUserId}`)
      .then((res) => {
        const project = res.data.projects.find(
          (p) => p[1] === currentProject.value
        );
        this.setState(
          {
            managerOptions: res.data.managers.map((manager) => {
              return { label: manager[0], value: manager[1], id: manager[2] };
            }),
            projectOptions: res.data.projects.map((p) => {
              return {
                label: p[0],
                value: p[1],
                automation: p[2],
              };
            }),
            selectedProject: currentProject,
            platformOptions: res.data.eod_platforms,
            taskOptions: res.data.eod_tasks,
            automation: project && project[2],
          },
          () => {
            this.getEodBugs(currentProject.value);
          }
        );
      })
      .catch((err) => {
        setBackdropLoader(false);
        console.error(err);
        Swal.fire({
          title: "EOD Report error",
          text: "There was a problem retrieving project data. You can still submit a manual EOD report",
          customClass: "Swal2-error-modal",
        });
        this.setState({
          auto: false,
        });
      });
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  handleAuto = (bool) => {
    this.setState({ auto: bool });
  };

  inputBlur = () => {
    if (this.timer) {
      this.timer = null;
    }
  };

  inputTimer = (e) => {
    if (this.timer) {
      this.timer.resetTimer();
    } else {
      this.timer = new Timer(1000, this.handleInput, e.target);
    }
  };

  handleInput = (elem) => {
    if (elem.id === "managerEmail") {
      this.setState((prevState) => {
        return {
          managerEmailInput: elem.value,
          autoManagerEmailInput: !!(prevState.auto && elem.value),
        };
      });
    }
    this.setState(
      {
        [`${elem.id}Input`]: elem.value,
      },
      () => {
        this.timer = null;
      }
    );
  };

  handleCancelButton = () => {
    const { modalAction } = this.props;
    Swal.fire({
      title: "Close Report",
      text: "Are you sure you would like to discard this EOD before sending?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        modalAction(false, "", {});
      }
    });
  };

  numberKeyDown = (e) => {
    if (e.target.id === "hours") {
      if (e.keyCode === 190 && !e.target.value) {
        e.preventDefault();
      } else if (e.target.value.length > 3 && e.keyCode !== 8) {
        e.preventDefault();
      }
    } else if (
      e.target.id === "bugsTotal" ||
      e.target.id === "testRuns" ||
      e.target.id === "updatedBugs"
    ) {
      if (e.keyCode === 190) {
        e.preventDefault();
      } else if (e.target.value.length > 2 && e.keyCode !== 8) {
        e.preventDefault();
      }
    } else if (e.target.id === "testSteps") {
      if (e.keyCode === 190) {
        e.preventDefault();
      } else if (e.target.value.length > 3 && e.keyCode !== 8) {
        e.preventDefault();
      }
    }
  };

  getEodBugs = (projectId) => {
    const { setBackdropLoader } = this.props;
    const { projectOptions } = this.state;
    setBackdropLoader(true);
    axios
      .get(`/eod_bugs/${projectId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const project = projectOptions.find((p) => p.value === projectId);
        this.setState({
          otherBugs: [...res.data.tickets, ...res.data.other_tickets],
          reopenedBugs: res.data.reopened_tickets,
          testStepsInput: res.data.completed_test_steps,
          automation: project && project.automation,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setBackdropLoader(false);
      });
  };

  handleProject = (selectedProject) => {
    this.setState({ selectedProject }, () => {
      this.getEodBugs(selectedProject.value);
    });
  };

  sendEodReport = () => {
    const {
      auto,
      autoManagerEmailInput,
      automationFeatureInput,
      bugLinksInput,
      bugs,
      bugsTotalInput,
      hoursInput,
      managerOptions,
      managerEmailInput,
      otherBugLinksInput,
      otherBugs,
      projectTextInput,
      reopenedBugLinksInput,
      reopenedBugs,
      reportDate,
      sendSelfCopy,
      selectedManager,
      selectedPlatforms,
      selectedProject,
      selectedTasks,
      summaryTextInput,
      testRunsInput,
      testStepsInput,
      updatedBugsInput,
    } = this.state;

    const { modalAction, data } = this.props;

    const bugLinkText = bugLinksInput;
    const reopenedBugLinkText = reopenedBugLinksInput;
    const otherBugLinkText = otherBugLinksInput;
    const form = new FormData();
    const managerId =
      managerOptions.find((o) => o.value === selectedManager?.value)?.id || 0;

    const managerEmails = managerEmailInput
      .replace(",", " ")
      .replace(/ +(?= )/g, "")
      .trim();
    let emails = managerEmails;
    if (selectedManager) {
      if (managerEmailInput) {
        emails = `${selectedManager.value} ${managerEmails}`;
      } else {
        emails = selectedManager.value;
      }
    } else {
      emails = managerEmails;
    }

    if (
      (autoManagerEmailInput || managerEmailInput) &&
      !validateEmail(managerEmailInput)
    ) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span aria-live='assertive'>One or more invalid manager emails</span>",
        classes: "red",
      });
      return null;
    }

    if (
      sendSelfCopy &&
      data.currentUserEmail &&
      !emails.includes(data.currentUserEmail)
    ) {
      emails = `${emails} ${data.currentUserEmail}`;
    }

    if (auto && selectedProject) {
      form.append("eod_report[project_id]", selectedProject.value);
    }

    if (!moment(reportDate).isSame(moment(), "day")) {
      form.append(
        "eod_report[created_at]",
        moment(reportDate).format("YYYY-MM-DD")
      );
    }

    if (auto) {
      form.append("eod_report[project_name]", selectedProject?.label || "");
    } else {
      form.append("eod_report[project_name]", projectTextInput);
    }

    form.append("eod_report[tester_id]", data.currentUserId);
    form.append("eod_report[manager_id]", managerId);
    form.append("eod_report[manager_email]", emails);
    form.append("eod_report[total_bugs]", parseInt(bugsTotalInput));
    form.append("eod_report[hours]", parseFloat(hoursInput));
    form.append("eod_report[summary]", summaryTextInput);

    if (automationFeatureInput) {
      form.append("eod_report[automation_feature]", automationFeatureInput);
    }

    form.append("eod_report[critical_high_bugs_count]", 0);
    form.append("eod_report[critical_high_bugs_text]", bugLinkText);
    form.append("eod_report[other_bugs_count]", 0);
    form.append("eod_report[[other_bugs_text]", otherBugLinkText);
    form.append("eod_report[reopened_bugs_count]", updatedBugsInput || 0);
    form.append("eod_report[reopened_bugs_text]", reopenedBugLinkText);
    form.append("eod_report[test_steps]", testStepsInput || 0);
    form.append("eod_report[test_runs]", testRunsInput || 0);

    if (selectedPlatforms.length > 0) {
      selectedPlatforms.forEach((p) => {
        form.append("eod_report[eod_platform_ids][]", p);
      });
    }

    if (selectedTasks.length > 0) {
      selectedTasks.forEach((t) => {
        form.append("eod_report[eod_task_ids][]", t);
      });
    }

    if (bugs.length > 0) {
      bugs.forEach((b) => {
        form.append("eod_report[ticket_ids][]", b.id);
      });
    }

    if (otherBugs.length > 0) {
      otherBugs.forEach((b) => {
        form.append("eod_report[ticket_ids][]", b.id);
      });
    }

    if (reopenedBugs.length > 0) {
      reopenedBugs.forEach((b) => {
        form.append("eod_report[reopened_ticket_ids][]", b.id);
      });
    }

    axios({
      method: "post",
      url: "/send_eod_report",
      data: form,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "EOD Report Sent",
          displayLength: 3000,
          classes: "green",
        });
        modalAction(false, "", {});
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Error sending report",
          displayLength: 3000,
          classes: "green",
        });
        console.error(err);
      });

    return null;
  };

  handleManager = (selectedManager) => {
    this.setState({ selectedManager });
  };

  handleSendSelfCopy = () => {
    this.setState((prevState) => {
      return { sendSelfCopy: !prevState.sendSelfCopy };
    });
  };

  handleTaskCheckbox = (e) => {
    const { selectedTasks } = this.state;
    const value = parseInt(e.target.value);
    const index = selectedTasks.findIndex((t) => t === value);
    if (index !== -1) {
      selectedTasks.splice(index, 1);
    } else {
      selectedTasks.push(value);
    }
    this.setState({ selectedTasks });
  };

  handlePlatformCheckbox = (e) => {
    const { selectedPlatforms } = this.state;
    const value = parseInt(e.target.value);
    const index = selectedPlatforms.findIndex((t) => t === value);
    if (index !== -1) {
      selectedPlatforms.splice(index, 1);
    } else {
      selectedPlatforms.push(value);
    }
    this.setState({ selectedPlatforms });
  };

  handleSingleDateChange = (date) => {
    this.setState({ reportDate: date });
  };

  onSingleFocusChange = ({ focused }) => {
    this.setState({ singlePickerFocus: focused });
  };

  isSubmitDisabled = () => {
    const {
      auto,
      summaryTextInput,
      bugsTotalInput,
      hoursInput,
      selectedManager,
      selectedProject,
      autoManagerEmailInput,
      managerEmailInput,
      projectTextInput,
    } = this.state;

    if (auto) {
      return (
        !summaryTextInput ||
        !bugsTotalInput ||
        !hoursInput ||
        !selectedManager ||
        !selectedProject ||
        (autoManagerEmailInput && !managerEmailInput)
      );
    }
    return (
      !summaryTextInput ||
      !bugsTotalInput ||
      !hoursInput ||
      !managerEmailInput ||
      !projectTextInput
    );
  };

  render() {
    const {
      auto,
      automation,
      automationFeature,
      bugsTotalInput,
      hoursInput,
      managerEmailInput,
      managerOptions,
      otherBugLinksInput,
      otherBugs,
      platformOptions,
      projectOptions,
      projectTextInput,
      reopenedBugLinksInput,
      reopenedBugs,
      reportDate,
      selectedManager,
      selectedPlatforms,
      selectedProject,
      selectedTasks,
      sendSelfCopy,
      singlePickerFocus,
      summaryTextInput,
      taskOptions,
      testRunsInput,
      testStepsInput,
      updatedBugsInput,
    } = this.state;

    const { modalAction } = this.props;

    return (
      <div
        aria-label="End of day report modal"
        role="dialog"
        style={{ height: "100%" }}
      >
        <ModalHeader
          modalAction={modalAction}
          title="End of Day Report"
          subTitle="Send your manager(s) an update on testing work completed today."
        />
        <div
          style={{
            height: "calc(90vh - 85px)",
            maxHeight: "630px",
            position: "relative",
            overflow: "auto",
            margin: "4px 0px",
          }}
          className="new-scrollbar"
        >
          <div
            id="create-report-container"
            style={{
              padding: "0px 25px 10px",
              opacity: "1",
              transition: "opacity 0.3s",
            }}
          >
            {/* put this into it's own component and is the basic info and switcher buttons  */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  aria-hidden
                  style={{ width: "25px" }}
                  src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/EOD_Basic_Info.svg"
                  alt=""
                />
                <h3
                  style={{
                    fontSize: "15px",
                    marginLeft: "8px",
                    fontFamily: "manrope",
                    color: "#242424",
                    fontWeight: "800",
                  }}
                >
                  Basic Info
                </h3>
              </div>
              <div
                aria-hidden
                style={{ display: "flex", alignItems: "center" }}
              >
                <SubmitModeSwitcher
                  onClick={() => {
                    this.handleAuto(true);
                  }}
                  $active={auto}
                >
                  Automatic
                </SubmitModeSwitcher>
                <SubmitModeSwitcher
                  onClick={() => {
                    this.handleAuto(false);
                  }}
                  $active={!auto}
                >
                  Manual
                </SubmitModeSwitcher>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <BugsFiledLabel>
                  Project
                  <span aria-hidden style={{ color: "#519acc" }}>
                    *
                  </span>
                </BugsFiledLabel>
                {auto ? (
                  <BasicSelect
                    options={projectOptions}
                    onChange={this.handleProject}
                    selectedOption={selectedProject}
                    listItemStyle={{ paddingTop: "4px", paddingBottom: "4px" }}
                    placeholder="Select Project"
                    searchEnabled
                    id="1"
                    width="220px"
                    maxHeight="155px"
                    containerStyle={{ height: "40px" }}
                    containerButtonStyle={{
                      height: "40px",
                      paddingRight: "12px",
                      paddingLeft: "12px",
                    }}
                    selectedItemStyle={{
                      lineHeight: "38px",
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                    inputStyle={{
                      lineHeight: "38px",
                      fontSize: "13px",
                      fontWeight: "400",
                      boxShadow: "none",
                    }}
                    listItemTextStyle={{
                      lineHeight: "34px",
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                    roundDropdownStyle={{ width: "18px", height: "18px" }}
                  />
                ) : (
                  <BugsFiledInput
                    defaultValue={projectTextInput}
                    onBlur={this.inputBlur}
                    maxLength={50}
                    id="projectText"
                    type="text"
                    onChange={this.inputTimer}
                    className="common-input browser-default"
                    placeholder="Enter project name."
                    style={{
                      width: "250px",
                      display: "block",
                      marginRight: "15px",
                      marginTop: "0px",
                      fontSize: "13px",
                    }}
                  />
                )}
              </div>
              <div aria-hidden style={{ marginBottom: "14px" }}>
                <BugsFiledLabel>
                  Report Date<span style={{ color: "#519acc" }}>*</span>
                </BugsFiledLabel>
                <div
                  className="common-date-picker"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "15px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon
                      style={{ width: "30px", verticalAlign: "bottom" }}
                      className="icons"
                    />
                  </div>
                  <SingleDatePicker
                    date={reportDate} // momentPropTypes.momentObj or null
                    onDateChange={this.handleSingleDateChange} // PropTypes.func.isRequired
                    focused={singlePickerFocus} // PropTypes.bool
                    onFocusChange={this.onSingleFocusChange} // PropTypes.func.isRequired
                    numberOfMonths={1}
                    placeholder="Select a date"
                    isOutsideRange={(day) =>
                      moment(day).day() === 0 || moment(day).day() === 6
                    }
                    orientation="horizontal"
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={
                      <RoundDropdown
                        svgStyles={{ width: "18px", height: "18px" }}
                      />
                    }
                    id="single_date" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {auto && (
                <div>
                  <BugsFiledLabel
                    style={{
                      marginTop: "21px",
                      paddingBottom: "4px",
                    }}
                  >
                    Manager
                    <span aria-hidden style={{ color: "#519acc" }}>
                      *
                    </span>
                  </BugsFiledLabel>
                  <BasicSelect
                    options={managerOptions}
                    onChange={this.handleManager}
                    selectedOption={selectedManager}
                    listItemStyle={{ paddingTop: "4px", paddingBottom: "0-px" }}
                    placeholder="Select Manager"
                    searchEnabled
                    id="2"
                    width="220px"
                    maxHeight="155px"
                    containerStyle={{ height: "40px" }}
                    containerButtonStyle={{
                      height: "40px",
                      paddingRight: "12px",
                      paddingLeft: "12px",
                    }}
                    selectedItemStyle={{
                      lineHeight: "38px",
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                    inputStyle={{
                      lineHeight: "38px",
                      fontSize: "13px",
                      fontWeight: "400",
                      boxShadow: "none",
                    }}
                    listItemTextStyle={{
                      lineHeight: "34px",
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                    roundDropdownStyle={{ width: "18px", height: "18px" }}
                  />
                </div>
              )}
              <div style={{ marginTop: "20px" }}>
                <BugsFiledLabel style={{ paddingBottom: "0px" }}>
                  {auto ? "Additional Manager Emails" : "Manager Email(s)"}
                </BugsFiledLabel>
                <BugsFiledInput
                  defaultValue={managerEmailInput}
                  type="text"
                  onBlur={this.inputBlur}
                  maxLength={300}
                  id="managerEmail"
                  onChange={this.inputTimer}
                  style={{
                    width: "370px",
                    display: "block",
                    marginTop: "0px",
                    fontSize: "13px",
                  }}
                  className="common-input browser-default"
                  placeholder="Enter one or more email addresses, separated by a comma"
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "solid 1px lightgray",
                width: "100%",
                marginTop: "20px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "28px 0 20px",
              }}
            >
              <img
                alt=""
                aria-hidden
                style={{ width: "20px" }}
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/EOD_Summary.svg"
              />
              <h3
                style={{
                  fontSize: "15px",
                  marginLeft: "10px",
                  fontFamily: "manrope",
                  color: "#242424",
                  fontWeight: "800",
                }}
              >
                Summary
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "21px",
              }}
            >
              {automation && (
                <div>
                  <span
                    style={{
                      fontWeight: "700",
                      display: "block",
                      color: "#242424",
                      paddingBottom: "4px",
                      fontSize: "13px",
                      fontFamily: "manrope",
                    }}
                  >
                    Project / Feature Name
                  </span>
                  <input
                    defaultValue={automationFeature}
                    onBlur={this.inputBlur}
                    maxLength={50}
                    id="automationFeature"
                    type="text"
                    onChange={this.inputTimer}
                    style={{
                      marginTop: "0px",
                      width: "250px",
                      display: "block",
                      marginRight: "20px",
                      height: "40px",
                      fontSize: "13px",
                    }}
                    className="common-input browser-default"
                    placeholder="Enter project or feature name"
                  />
                </div>
              )}
              {automation && (
                <div>
                  <BugsFiledLabel>Automated Test Cases</BugsFiledLabel>
                  <BugsFiledInput
                    aria-label="Enter number of test cases tested"
                    onBlur={this.inputBlur}
                    defaultValue={testStepsInput || ""}
                    onKeyDown={this.numberKeyDown}
                    type="number"
                    id="testSteps"
                    placeholder="#"
                    min={1}
                    max={999}
                    onChange={this.inputTimer}
                    className="common-input browser-default"
                  />
                </div>
              )}
              <div>
                <BugsFiledLabel>
                  Total Bugs Filed
                  <span aria-hidden style={{ color: "#519acc" }}>
                    *
                  </span>
                </BugsFiledLabel>
                <BugsFiledInput
                  aria-label="Enter total number of bugs filed"
                  onBlur={this.inputBlur}
                  defaultValue={bugsTotalInput || ""}
                  onKeyDown={this.numberKeyDown}
                  type="number"
                  id="bugsTotal"
                  placeholder="#"
                  min={1}
                  max={999}
                  onChange={this.inputTimer}
                  className="common-input browser-default"
                  style={{
                    height: "40px",
                    fontSize: "14px",
                    width: "55px",
                    marginTop: "0px",
                  }}
                />
              </div>
              {!automation && (
                <div>
                  <BugsFiledLabel>Updated Tickets</BugsFiledLabel>
                  <BugsFiledInput
                    aria-label="Enter number of updated tickets"
                    onBlur={this.inputBlur}
                    defaultValue={updatedBugsInput || ""}
                    onKeyDown={this.numberKeyDown}
                    type="number"
                    id="updatedBugs"
                    placeholder="#"
                    min={1}
                    max={999}
                    onChange={this.inputTimer}
                    className="common-input browser-default"
                    style={{
                      height: "40px",
                      fontSize: "14px",
                      width: "55px",
                      marginTop: "0px",
                    }}
                  />
                </div>
              )}
              {!automation && (
                <div>
                  <BugsFiledLabel>
                    Test Steps
                    <span
                      aria-hidden
                      style={{ color: "#519acc" }}
                      aria-label="Enter number of test steps completed"
                    >
                      *
                    </span>
                  </BugsFiledLabel>
                  <BugsFiledInput
                    aria-label="Enter number of test steps completed"
                    onBlur={this.inputBlur}
                    value={testStepsInput || ""}
                    onKeyDown={this.numberKeyDown}
                    type="number"
                    id="testSteps"
                    placeholder="#"
                    min={1}
                    max={999}
                    onChange={(e) => {
                      this.inputTimer(e);
                      this.setState({ testStepsInput: e.target.value });
                    }}
                    className="common-input browser-default"
                    style={{
                      height: "40px",
                      fontSize: "14px",
                      width: "55px",
                      marginTop: "0px",
                    }}
                  />
                </div>
              )}
              {!automation && (
                <div>
                  <BugsFiledLabel>Test Runs</BugsFiledLabel>
                  <BugsFiledInput
                    aria-label="Enter number of test runs"
                    onBlur={this.inputBlur}
                    defaultValue={testRunsInput || ""}
                    onKeyDown={this.numberKeyDown}
                    type="number"
                    id="testRuns"
                    placeholder="#"
                    min={1}
                    max={999}
                    onChange={this.inputTimer}
                    className="common-input browser-default"
                    style={{
                      height: "40px",
                      fontSize: "14px",
                      width: "55px",
                      marginTop: "0px",
                    }}
                  />
                </div>
              )}
              <div>
                <BugsFiledLabel>
                  Hours Spent
                  <span aria-hidden style={{ color: "#519acc" }}>
                    *
                  </span>
                </BugsFiledLabel>
                <BugsFiledInput
                  aria-label="Enter number of hours spent"
                  onBlur={this.inputBlur}
                  defaultValue={hoursInput || ""}
                  onKeyDown={this.numberKeyDown}
                  type="number"
                  id="hours"
                  placeholder="0.00"
                  min={1}
                  max={999}
                  onChange={this.inputTimer}
                  className="common-input browser-default"
                  style={{
                    height: "40px",
                    fontSize: "14px",
                    width: "55px",
                    marginTop: "0px",
                  }}
                />
              </div>
            </div>
            <BugsFiledLabel
              style={{
                marginTop: "10px",
                marginBottom: auto && otherBugs.length > 0 ? "16px" : "0px",
              }}
            >
              {auto ? "New Bugs" : "List of New Bugs"}
            </BugsFiledLabel>
            <div>
              {auto && !automation && otherBugs.length > 0 ? (
                otherBugs.map((bug) => {
                  const { id, ticket_priority_name, title, ticket_identifier } =
                    bug;
                  return (
                    <ReopenedBug key={id}>
                      <ReopenedBugPriority $priority={ticket_priority_name} />
                      <ReopenedBugLink href={`/bugs/${ticket_identifier}`}>
                        {ticket_identifier}
                      </ReopenedBugLink>
                      {` - ${title}`}
                    </ReopenedBug>
                  );
                })
              ) : (
                <textarea
                  onBlur={this.inputBlur}
                  defaultValue={otherBugLinksInput}
                  maxLength={10000}
                  id="otherBugLinks"
                  onChange={this.inputTimer}
                  style={{ marginTop: "0px", fontSize: "13px" }}
                  className="common-textarea browser-default"
                  placeholder={
                    auto && !automation
                      ? "No new bugs were filed by you in Test Platform today. For any bugs filed outside of Test Platform, please provide a unique URL for each separated by a space or a line break."
                      : "Include the list of bugs filed by pasting the URL and Bug Title into this field."
                  }
                />
              )}
            </div>
            {!automation && (
              <div>
                <BugsFiledLabel
                  style={{
                    marginTop: "6px",
                    marginBottom:
                      auto && reopenedBugs.length > 0 ? "16px" : "0px",
                  }}
                >
                  {auto ? "Updated Bugs" : "List of Updated Bugs"}
                </BugsFiledLabel>
                {auto && reopenedBugs.length > 0 && (
                  <div>
                    {reopenedBugs.map((bug) => {
                      const {
                        ticket_priority_name,
                        ticket_identifier,
                        id,
                        title,
                      } = bug;
                      return (
                        <ReopenedBug key={id}>
                          <ReopenedBugPriority
                            $priority={ticket_priority_name}
                          />
                          <ReopenedBugLink href="/">
                            {ticket_identifier}
                          </ReopenedBugLink>
                          {title}
                        </ReopenedBug>
                      );
                    })}
                  </div>
                )}
                {(!auto || reopenedBugs.length === 0) && (
                  <textarea
                    onBlur={this.inputBlur}
                    defaultValue={reopenedBugLinksInput}
                    maxLength={8000}
                    id="reopenedBugLinks"
                    onChange={this.inputTimer}
                    style={{ marginTop: "0px", fontSize: "13px" }}
                    className="common-textarea"
                    placeholder={
                      auto && !automation
                        ? "No bugs were updated by you in Test Platform today. For any bugs updated outside of Test Platform, please provide a unique URL for each separated by a space or a line break."
                        : "Include the list of bugs updated by pasting the URL and Bug Title into this field."
                    }
                  />
                )}
              </div>
            )}
            {!automation && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "50%" }}>
                  <BugsFiledLabel
                    style={{
                      marginTop: "6px",
                      marginBottom: "0px",
                    }}
                  >
                    What did you work on today?
                  </BugsFiledLabel>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    {taskOptions.map((o) => {
                      return (
                        <label
                          key={o.id}
                          style={{
                            float: "left",
                            paddingTop: "5px",
                            width: "50%",
                            textAlign: "left",
                          }}
                          className="common-checkbox-2"
                        >
                          <input
                            style={{ width: "100px" }}
                            value={o.id}
                            onChange={this.handleTaskCheckbox}
                            className="filled-in"
                            checked={selectedTasks.indexOf(o.id) !== -1}
                            type="checkbox"
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              paddingLeft: "28px",
                              marginRight: "20px",
                              color: "#242424",
                              fontSize: "13px",
                              paddingTop: "2px",
                            }}
                          >
                            {o.name}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <span
                    style={{
                      fontWeight: "700",
                      display: "block",
                      paddingBottom: "0px",
                      fontSize: "13px",
                      fontFamily: "manrope",
                      marginTop: "6px",
                      marginBottom: "0px",
                      color: "#242424",
                    }}
                  >
                    Platforms Tested
                  </span>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    {platformOptions.map((o) => {
                      return (
                        <label
                          key={o.id}
                          style={{
                            float: "left",
                            paddingTop: "5px",
                            width: "50%",
                            textAlign: "left",
                          }}
                          className="common-checkbox-2"
                        >
                          <input
                            style={{ width: "100px" }}
                            value={o.id}
                            onChange={this.handlePlatformCheckbox}
                            className="filled-in"
                            checked={selectedPlatforms.indexOf(o.id) !== -1}
                            type="checkbox"
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              paddingLeft: "28px",
                              marginRight: "20px",
                              color: "#242424",
                              fontSize: "13px",
                              paddingTop: "2px",
                            }}
                          >
                            {o.name}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                borderBottom: "solid 1px lightgray",
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            />
            <div style={{ marginTop: "10px" }}>
              <span
                style={{
                  fontWeight: "700",
                  display: "block",
                  paddingBottom: "0px",
                  fontSize: "13px",
                  fontFamily: "manrope",
                  marginTop: "6px",
                  marginBottom: "0px",
                  color: "#242424",
                }}
              >
                Additional Notes<span style={{ color: "#519acc" }}>*</span>
              </span>
              <textarea
                defaultValue={summaryTextInput}
                onBlur={this.inputBlur}
                id="summaryText"
                placeholder="Feel free to include notes about the project, test cases you executed or anything else that might be relevant to share with the rest of the team."
                onChange={this.inputTimer}
                maxLength={8000}
                style={{ marginTop: "0px", fontSize: "13px" }}
                className="common-textarea"
              />
            </div>
            {/* Footer */}
            <div
              style={{
                marginLeft: "auto",
                width: "260px",
                textAlign: "left",
                marginTop: "12px",
                display: "block",
                color: "#242424",
              }}
            >
              <HarvestLogo
                alt="Harvest logo"
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/eod-report/harvest_logo.svg"
              />
              <p
                style={{
                  fontSize: "12px",
                  lineHeight: "22px",
                  marginBottom: "20px",
                  marginTop: "6px",
                }}
              >
                Remember to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://plusqa.harvestapp.com/time"
                >
                  log your time in Harvest
                </a>
              </p>
            </div>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <label
                style={{ float: "left", paddingTop: "5px" }}
                className="common-checkbox-2"
              >
                <input
                  style={{ width: "100px" }}
                  onChange={this.handleSendSelfCopy}
                  className="filled-in"
                  checked={sendSelfCopy}
                  type="checkbox"
                />
                <span
                  style={{
                    whiteSpace: "nowrap",
                    paddingLeft: "28px",
                    paddingTop: "1px",
                    marginRight: "20px",
                    fontWeight: "600",
                    fontFamily: "manrope",
                    color: "#242424",
                    fontSize: "13px",
                  }}
                >
                  Send Myself a Copy
                </span>
              </label>
              <button
                type="button"
                style={{ width: "125px", height: "44px" }}
                onClick={this.handleCancelButton}
                className="common-button-cancel"
              >
                Cancel
              </button>
              <button
                type="button"
                style={{ width: "125px", height: "44px" }}
                disabled={this.isSubmitDisabled()}
                onClick={this.sendEodReport}
                className="common-button-submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EodReport.propTypes = {
  modalAction: PropTypes.func,
  setBackdropLoader: PropTypes.func,
  data: PropTypes.shape({
    backdropClickCheck: PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
    }),
    currentProject: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    currentUserEmail: PropTypes.string,
    currentUserId: PropTypes.number,
    customClass: PropTypes.string,
  }),
};

const ReopenedBug = styled.div`
  min-height: 30px;
  line-height: 22px;
  font-size: 13px;
  padding: 0px 10px 5px 14px;
  position: relative;
`;

const ReopenedBugPriority = styled.div`
  height: 10px;
  display: block;
  position: absolute;
  left: 0px;
  top: 5px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => {
    const COLOR_MAP = {
      Blocker: "rgb(255, 0, 0)",
      High: "rgb(255, 153, 0)",
      Normal: "#0066cc",
      Low: "#009900",
      default: "#009900",
    };
    return COLOR_MAP[props.$priority] || COLOR_MAP.default;
  }};
`;

const ReopenedBugLink = styled.a`
  color: #519acc;
  font-size: 13px;
  font-weight: 600;
`;

const SubmitModeSwitcher = styled.button`
  color: ${(props) => (props.$active ? "white" : "#519acc")};
  margin-right: 10px;
  font-family: Manrope;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? "#519acc" : "white")};
  border: solid 2px #519acc;
  width: 118px;
  border-radius: 15px;
  height: 28px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;

  &:focus {
    background-color: ${(props) => (props.$active ? "#519acc" : "white")};
    color: ${(props) => (props.$active ? "white" : "#519acc")};
  }
`;

const BugsFiledInput = styled.input`
  height: 40px;
  width: 55px;
  margin-top: 0px;
  font-size: 14px;
`;

const BugsFiledLabel = styled.span`
  font-weight: 700;
  display: block;
  color: #242424;
  padding-bottom: 0px;
  font-size: 13px;
  font-family: manrope;
`;

const HarvestLogo = styled.img`
  display: block;
  width: 90px;
  filter: invert(54%) sepia(77%) saturate(3549%) hue-rotate(347deg)
    brightness(98%) contrast(86%);
`;

export default EodReport;
