import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Select from "react-select";
import { wikiAssessmentTypes } from "../../../../types/wiki";
import { userTypes } from "../../../../types";
import useDebounce from "../../../../hooks/useDebounce";
import ApiClient from "../../../../utils/ApiClient";
import LoadingSpinner from "../../../common/loading_spinner";
import CancelIcon from "../../../icons/cancel_icon";

const UserInvites = ({
  assessment,
  handleUsersUpdate,
  handleRemoveUser,
  currentList,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const inputRef = useRef(null);

  const options = useMemo(() => {
    return userSuggestions.map((user) => ({
      value: user.id,
      label: `${user.name} (${user.email})`,
    }));
  }, [userSuggestions]);

  const onError = (error) => {
    // eslint-disable-next-line no-undef
    M.toast({
      html: error.message,
      classes: "red darken-1 error-toast",
    });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleUserSelect = (selectedOption) => {
    const user = userSuggestions.find((u) => u.id === selectedOption.value);
    setInputValue("");
    setUserSuggestions([]);
    inputRef.current.blur();
    handleUsersUpdate(user);
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const api = new ApiClient();
      let search = `s=${debouncedSearchTerm}`;
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (debouncedSearchTerm.toLowerCase().match(emailRegex)) {
        search = `email=${debouncedSearchTerm}`;
      }
      const response = await api.get(
        `/wiki-assessments/${assessment.id}/users?${search}`
      );
      setUserSuggestions(response.data);
    } catch (error) {
      onError(error);
      setUserSuggestions([]);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearchTerm, assessment.id]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      fetchUsers();
    }
  }, [debouncedSearchTerm, fetchUsers]);

  return (
    <InviteForm>
      <InviteLabel>Invite Users to Assessment</InviteLabel>
      <InviteInputWrapper>
        <UserLookup
          ref={inputRef}
          aria-label="User lookup field for assessment invitations"
          placeholder="Search users by name or email"
          classNamePrefix="Select"
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleUserSelect}
          isClearable
          isMulti={false}
          isLoading={loading}
          loadingMessage={() => "Searching for users..."}
          options={options}
          value={null}
          isSearchable
          menuPortalTarget={document.querySelector("body")}
          noOptionsMessage={() => "No valid users found."}
          components={{
            LoadingIndicator: (props) => {
              return <UserLookupLoadingSpinner loading size={28} {...props} />;
            },
          }}
          styles={{
            placeholder: (base, state) => ({
              ...base,
              display: state.isFocused || state.hasValue ? "none" : "block",
            }),
            container: (base) => ({
              ...base,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#519acc",
            },
          })}
        />
      </InviteInputWrapper>
      <UserList>
        {currentList.map((user) => (
          <UserDisplay key={user.id}>
            <div>{user.name}</div>
            <ClearUserButton
              type="button"
              onClick={() => handleRemoveUser(user)}
            >
              <CancelIcon height={10} width={10} color="#242424" />
            </ClearUserButton>
          </UserDisplay>
        ))}
      </UserList>
    </InviteForm>
  );
};

export const InviteForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27px;
`;

export const InviteLabel = styled.label`
  font-family: "Manrope";
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
  color: #333333;
`;

export const InviteInputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const UserLookupStyle = css`
  /* height: 38px; */
  width: 100%;
  max-width: 450px;
  border-radius: 5px;

  .Select__container {
    height: 38px;
    min-height: 38px;
  }

  .Select__control {
    height: 38px;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
  }

  .Select__value {
    height: 38px;
  }

  .Select__value-container {
    height: 38px;
    min-height: 38px;
    padding: 0 12px;

    & > div:not(.Select__placeholder) {
      margin: 0;
    }
  }

  .Select__input {
    margin: 0;

    input {
      height: 38px;
      max-height: 38px;
    }
  }

  .Select__placeholder {
    height: 38px;
    max-height: 38px;
    line-height: 38px;
    top: 0;
    transform: none;
  }

  .Select__indicators {
    position: relative;
  }
`;

export const UserLookup = styled(Select)`
  ${UserLookupStyle}
`;

const UserLookupLoadingSpinner = styled(LoadingSpinner)`
  top: 5px;
  left: -200%;
`;

export const UserList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;

  &::after {
    content: "";
    flex: auto;
  }
`;

export const UserDisplay = styled.li`
  padding: 4px 16px;
  background-color: #d7d7d7;
  color: #242424;
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  font-family: "Manrope";
  margin-top: 4px;
  display: flex;
  align-items: baseline;
`;

const ClearUserButton = styled.button`
  background: transparent;
  border: none;
  margin-left: 4px;
  cursor: pointer;
`;

UserInvites.propTypes = {
  handleUsersUpdate: PropTypes.func.isRequired,
  assessment: wikiAssessmentTypes.isRequired,
  handleRemoveUser: PropTypes.func.isRequired,
  currentList: PropTypes.arrayOf(userTypes),
};

UserInvites.defaultProps = {
  currentList: [],
};

export default UserInvites;
