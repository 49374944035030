import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ActivityFeed from "./activity_feed";
import ErrorBoundary from "../common/error_boundary";
import StarIcon from "../icons/dashboard/star_icon";

const ActivityWrapper = (props) => {
  const { activityCount, project } = props;

  return (
    <ActivityOuter>
      {activityCount > 0 ? (
        <ActivityContent>
          <ActivityHeader>
            <ActivityTitle className="performance-large-title">
              Activity Feed
            </ActivityTitle>
          </ActivityHeader>
          <ActivityScrollContainer>
            <ErrorBoundary>
              <ActivityFeed
                projectId={project?.id}
                big
                activityCount={activityCount}
              />
            </ErrorBoundary>
          </ActivityScrollContainer>
        </ActivityContent>
      ) : (
        <EmptyActivity>
          <StarIcon
            stroke="#519acc"
            fill="transparent"
            width={60}
            height={60}
          />
          <EmptyActivityTitle>
            No Recent Activity Results Found
          </EmptyActivityTitle>
          <EmptyActivityDescription>
            As you use Test Platform, activity entries from the past 2 months
            will appear here. Try creating a bug or completing a Test Pass!
          </EmptyActivityDescription>
        </EmptyActivity>
      )}
    </ActivityOuter>
  );
};

const ActivityOuter = styled.div`
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 20px 30px 20px;
  margin: 5px;
  flex: 1;
`;

const ActivityContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ActivityHeader = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
`;

const ActivityTitle = styled.p`
  margin-right: 15px;
  width: fit-content;
`;

const ActivityScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  font-family: arial;
  flex: 1;
`;

const EmptyActivity = styled.div`
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyActivityTitle = styled.h2`
  margin: 18px 0px;
`;

const EmptyActivityDescription = styled.p`
  max-width: 420px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 20px;
`;

ActivityWrapper.propTypes = {
  project: PropTypes.object.isRequired,
  activityCount: PropTypes.number.isRequired,
};

export default ActivityWrapper;
