/* eslint-disable no-param-reassign */

// import { createSlice, createSelector } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const INITITAL_STATE = {};

export const deviceLabSlice = createSlice({
  name: "deviceLab",
  initialState: INITITAL_STATE,
  reducers: {},
});

// actions
export const deviceLabActions = deviceLabSlice.actions;

// selectors
// const getState = (state) => state.deviceLab;

export const selectors = {};

export const DEVICE_LAB_INDEX_TAG = "deviceLabIndex";
export const DEVICE_LAB_SETTINGS_TAG = "deviceLabSettings";
export const DEVICE_LAB_USER_DEVICES_TAG = "deviceLabUserDevices";

export const DEVICE_LAB_TAGS = [
  DEVICE_LAB_INDEX_TAG,
  DEVICE_LAB_SETTINGS_TAG,
  DEVICE_LAB_USER_DEVICES_TAG,
];

// RTK Query endpoints
export const deviceLabEndpoints = ({ query, mutation }) => {
  return {
    // queries
    getDeviceLabIndex: query({
      query: (q) => {
        return {
          url: `device_lab?${q}`,
        };
      },
      providesTags: [DEVICE_LAB_INDEX_TAG],
    }),
    getDeviceLabSettings: query({
      query: () => "device_lab/settings",
      providesTags: [DEVICE_LAB_SETTINGS_TAG],
    }),
    getUserDevices: query({
      query: (id) => `users/${id}/devices`,
      providesTags: [DEVICE_LAB_USER_DEVICES_TAG],
    }),
    getUserDeviceTier: query({
      query: (id) => `users/${id}/device_tier`,
    }),
    getDeviceCheckoutDate: query({
      query: (id) => {
        return {
          url: `devices/${id}/checkout_date`,
          responseHandler: (res) => res.text(),
        };
      },
      providesTags: [DEVICE_LAB_INDEX_TAG],
    }),

    // mutations
    checkOutDevice: mutation({
      query: (body) => ({
        url: `devices/${body.device_id}/check_out`,
        method: "POST",
        body,
      }),
      invalidatesTags: [DEVICE_LAB_INDEX_TAG, DEVICE_LAB_USER_DEVICES_TAG],
    }),
    checkInDevice: mutation({
      query: (body) => ({
        url: `devices/${body.device_id}/check_in`,
        method: "POST",
        body,
      }),
      invalidatesTags: [DEVICE_LAB_INDEX_TAG, DEVICE_LAB_USER_DEVICES_TAG],
    }),
    editDevice: mutation({
      query: (body) => ({
        url: `devices/${body.device.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [
        DEVICE_LAB_INDEX_TAG,
        DEVICE_LAB_USER_DEVICES_TAG,
        DEVICE_LAB_SETTINGS_TAG,
      ],
    }),
    createDevice: mutation({
      query: (body) => ({
        url: "devices",
        method: "POST",
        body,
      }),
      invalidatesTags: [DEVICE_LAB_INDEX_TAG],
    }),
    updateDeviceLabSettings: mutation({
      query: (body) => ({
        url: "admin_panel/app_version",
        method: "PATCH",
        body,
      }),
      invalidatesTags: [DEVICE_LAB_SETTINGS_TAG],
    }),
  };
};

export default deviceLabSlice.reducer;
